import { cn } from "@/client/lib/classnames";
import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leadingIcon?: any;
  label?: string;
  labelDescription?: string;
}

export function InputContainer({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        "border dark:border-neutral-700 border-neutral-200 rounded-md flex items-stretch h-10 overflow-hidden dark:text-white",
        className
      )}
    >
      {children}
    </div>
  );
}

const Input = ({
  value,
  placeholder,
  leadingIcon,
  label,
  labelDescription,
  required = false,
  disabled = false,
  type = "text",
  className,
  ...props
}: InputProps) => {
  const isFileInput = type === "file";

  return (
    <div className={className}>
      {label ? (
        <p
          className={`text-p-s dark:text-white font-medium ${
            !labelDescription ? "mb-2" : null
          }`}
        >
          {label}
          <>{required ? <span className="text-red-600">*</span> : null}</>
        </p>
      ) : null}
      {labelDescription ? (
        <p className="mb-2 leading-tight text-p-s text-neutral-600 dark:text-neutral-400">
          {labelDescription}
        </p>
      ) : null}
      <InputContainer
        className={cn({
          "bg-neutral-100 dark:bg-neutral-700 dark:text-neutral-400 text-neutral-500":
            disabled,
          "gap-2 p-2 items-center": !isFileInput,
        })}
      >
        <div className="flex-shrink-0 text-neutral-400 dark:text-neutral-500">
          {leadingIcon ? leadingIcon : null}
        </div>

        <input
          className={cn("flex-grow bg-transparent", {
            "placeholder:text-neutral-400 text-p-m overflow-scroll dark:placeholder:text-neutral-500":
              !isFileInput,
            "w-full cursor-pointer dark:text-neutral-400 file:cursor-pointer file:mr-4 file:px-4 file:border-0 file:font-medium file:bg-neutral-200 file:text-neutral-900 hover:file:bg-neutral-300 dark:file:bg-neutral-700 dark:file:text-neutral-200 dark:hover:file:bg-neutral-600 file:border-neutral-300 dark:file:border-neutral-600 file:h-full file:rounded-l-md":
              isFileInput,
          })}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          type={type}
          {...props}
        />
      </InputContainer>
    </div>
  );
};

export default Input;
