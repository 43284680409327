import { Check } from "../icons/check";

const CheckBox = ({
  id,
  isChecked,
  onChange,
  size = "5",
}: {
  id: string;
  isChecked: boolean;
  onChange: any;
  size?: string;
}) => {
  return (
    <div
      className={`flex-shrink-0 w-${size} h-${size} relative cursor-pointer`}
    >
      {isChecked ? (
        <Check
          size={20}
          className="text-white absolute dark:text-neutral-900"
        />
      ) : null}

      <input
        id={id}
        type="checkbox"
        value=""
        checked={isChecked}
        onChange={onChange}
        className={`flex-shrink-0 appearance-none w-${size} h-${size} border-2  cursor-pointer rounded-md dark:border-neutral-700 border-neutral-200 accent-black dark:accent-white ${
          isChecked
            ? "bg-neutral-900 dark:bg-white dark:border-white border-neutral-900"
            : "hover:bg-neutral-100 dark:hover:bg-neutral-800"
        }`}
      />
    </div>
  );
};

export default CheckBox;
