import React from "react";

import { ProjectSaleManagerMachineContext } from "../../ProjectSaleManagerMachineContext";
import Image from "next/image";
import { cloudinaryLoader } from "@/client/lib/helpers";
import { useSelector } from "@xstate/react";
import { createEmptyActor } from "xstate";
import { Hex } from "viem";
import { purchaseTrackingManagerMachineContext } from "@/client/components/common/PurchaseTrackingManagerMachineContext";

/**
 * Displays the user's purchased token or the project's featured token based on the purchase state.
 *
 * - **Pre-Purchase**: Shows the project's featured token if available.
 * - **Ongoing Purchase**: Overlays a "Purchase in progress" message on the featured token.
 *
 * This component relies on the `PurchaseMachineContext` to determine the current state of the purchase process.
 */
export function ProjectSaleTokenDisplay({ txHash }: { txHash?: Hex | null }) {
  const artblocksProject = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.project;
    }
  );

  const purchaseInitiationMachine =
    ProjectSaleManagerMachineContext.useSelector((state) => {
      return state.context.purchaseInitiationMachine;
    });

  const purchaseInitiationState = useSelector(
    purchaseInitiationMachine ?? createEmptyActor(),
    (state) => {
      if ("value" in state) {
        return state;
      }

      return null;
    }
  );

  const purchaseTrackingMachineRef =
    purchaseTrackingManagerMachineContext.useSelector(
      (state) => state.context.purchaseTrackingMachines[txHash ?? ""],
      (a, b) => {
        return a && b && Object.keys(a).join() === Object.keys(b).join();
      }
    );

  const purchaseTrackingState = useSelector(
    purchaseTrackingMachineRef ?? createEmptyActor(),
    (state) => {
      if ("value" in state) {
        return state;
      }

      return null;
    }
  );

  let purchaseOverlayText: string | null = "Waiting on purchase...";
  if (
    purchaseInitiationState?.matches("initiatingPurchase") ||
    purchaseTrackingState?.matches("awaitingPurchaseConfirmation") ||
    purchaseTrackingState?.matches("awaitingTokenSync") ||
    purchaseTrackingState?.matches("error")
  ) {
    purchaseOverlayText = "Purchase in progress...";
  } else if (purchaseTrackingState?.matches("tokenReady")) {
    purchaseOverlayText = null;
  }

  const featuredImageUrl = artblocksProject?.featured_token?.[0]?.media_url;
  const mintedTokenLiveViewUrl =
    purchaseTrackingState?.context.mintedToken?.live_view_url;

  // can only use cloudinary loader if loading project image from cloudinary. The fallback image may not be from cloudinary
  const useCloudinaryLoader =
    !!artblocksProject?.featured_token?.[0]?.media_url;

  if (!featuredImageUrl && !mintedTokenLiveViewUrl) {
    return null;
  }

  return (
    // Note: The breakpoint classes are based on estimated screen dimensions,
    // assuming the height scales with width. We set an image height to prevent
    // modal overflow, providing a practical compromise that allows for user
    // scrolling. This approach should ensure the modal appears appropriately in
    // most cases.
    <div className="relative w-full p-10 overflow-hidden rounded-lg h-60 md:h-72 xl:h-80 2xl:h-96 bg-neutral-200 dark:bg-neutral-800">
      <div className="w-full h-full">
        {mintedTokenLiveViewUrl ? (
          <div
            className="max-w-full max-h-full top-1/2 relative -translate-y-1/2 m-auto"
            style={{
              aspectRatio: artblocksProject?.aspect_ratio ?? 1,
            }}
          >
            <iframe
              src={mintedTokenLiveViewUrl}
              className="w-full h-full"
              sandbox="allow-scripts allow-downloads"
            />
          </div>
        ) : (
          <div className="relative h-full blur-lg grayscale">
            {/* TODO: Break out an optimized image component */}
            <Image
              src={featuredImageUrl ?? ""}
              alt="featured token"
              loader={
                useCloudinaryLoader
                  ? (props) => cloudinaryLoader(false, props)
                  : undefined
              }
              placeholder={"empty"}
              className="w-full h-full"
              fill
              // 640 is the default sm breakpoint for tailwind
              sizes={"(min-width: 640px) 30vw, 100vw"}
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        )}
        {purchaseOverlayText ? (
          <div className={`absolute inset-0 flex justify-center items-center`}>
            <div className="px-2 py-1 text-white bg-black rounded-lg text-p-s bg-opacity-20">
              <p>{purchaseOverlayText}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
