import { useState, useEffect } from "react";
import { useInterval } from "usehooks-ts";
import ms from "ms";
import InfiniteScroll from "react-infinite-scroll-component";

import { Activity } from "shared/types/activity";
import { listActivity, ListActivityParams } from "client/lib/api";
import Loading from "client/components/common/Loading";
import { Platform } from "shared/platforms";
import ActivityItem from "client/components/common/ActivityItem";
import { useAuth } from "@/client/components/common/AuthProvider";

const LIMIT = 20;
const REFRESH_INTERVAL_MS = ms("10s");

const CollectionActivity = ({
  filters,
  collection,
}: {
  filters: any;
  collection: any;
}) => {
  const { address: currentUserAddress } = useAuth();

  const kind = filters[0];
  const [priceMin, setPriceMin] = useState<number>();
  const [priceMax, setPriceMax] = useState<number>();
  const [platform, setPlatform] = useState<Platform>();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);
  const [onlySansaListings, setOnlySansaListings] = useState<boolean>(false);
  const [onlyNotableAddresses, setOnlyNotableAddresses] =
    useState<boolean>(false);
  const [onlyRelistingListings, setOnlyRelistingListings] =
    useState<boolean>(false);
  const [onlyNewFloorListings, setOnlyNewFloorListings] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>();
  const [activities, setActivities] = useState<Activity[]>();

  useEffect(() => {
    fetchInitialActivity();
  }, [
    kind,
    priceMin,
    priceMax,
    platform,
    onlySansaListings,
    onlyNewFloorListings,
    onlyNotableAddresses,
    onlyRelistingListings,
  ]);

  useInterval(() => {
    fetchLatestActivity();
  }, REFRESH_INTERVAL_MS);

  const fetchInitialActivity = async () => {
    setLoading(true);
    const response = await fetchActivity();
    setActivities(response);
    setHasMore(response?.length === LIMIT);
    setLoading(false);
  };

  const fetchLatestActivity = async () => {
    if (!activities?.length) return;

    const response = await fetchActivity({
      occurredAtAfter: activities?.[0]?.occurredAt,
    });

    setActivities([...response, ...activities]);
  };

  const fetchMoreActivity = async () => {
    if (!activities?.length) return;

    const response = await fetchActivity({
      occurredAtBefore: activities?.[activities.length - 1]?.occurredAt,
    });

    setActivities([...activities, ...response]);
    setHasMore(response?.length === LIMIT);
  };

  const fetchActivity = async ({
    occurredAtAfter,
    occurredAtBefore,
  }: {
    occurredAtAfter?: Date;
    occurredAtBefore?: Date;
  } = {}) => {
    let params = buildQueryParams({ occurredAtAfter, occurredAtBefore });
    let response = await listActivity(params);
    return response;
  };

  const buildQueryParams = ({
    occurredAtAfter,
    occurredAtBefore,
  }: {
    occurredAtAfter?: Date;
    occurredAtBefore?: Date;
  }) => {
    const params = { limit: LIMIT } as ListActivityParams;
    params.filters = { kind };

    params.filters.collectionId = collection?.id;
    if (occurredAtAfter) params.filters.occurredAtAfter = occurredAtAfter;
    if (occurredAtBefore) params.filters.occurredAtBefore = occurredAtBefore;
    if (priceMin) params.filters.priceMin = priceMin;
    if (priceMax) params.filters.priceMax = priceMax;
    if (platform) params.filters.platform = platform;
    if (onlySansaListings) params.filters.onlySansaListings = onlySansaListings;
    if (onlyNotableAddresses)
      params.filters.onlyNotableAddresses = onlyNotableAddresses;
    if (onlyRelistingListings)
      params.filters.onlyRelistingListings = onlyRelistingListings;
    if (onlyNewFloorListings)
      params.filters.onlyNewFloorListings = onlyNewFloorListings;
    return params;
  };

  return (
    <div>
      <div className="dark:text-white">
        <div>
          {loading ? (
            <div className="h-96 flex flex-col items-center justify-center">
              <Loading />
            </div>
          ) : (
            <>
              {!activities?.length ? (
                <EmptyState />
              ) : (
                <InfiniteScroll
                  dataLength={activities.length}
                  next={fetchMoreActivity}
                  hasMore={hasMore === true}
                  loader={
                    <p className="mx-auto mt-8 text-sm text-center text-neutral-500">
                      Loading more...
                    </p>
                  }
                  endMessage={""}
                >
                  <div className="mt-8 md:md:mt-0 md:pt-8 px-8 sm:px-10 md:p-10 flex flex-col gap-4 sm:gap-8">
                    <div className="hidden bg-neutral-100 xl:flex rounded-2xl py-4 mt-8 md:mt-0 dark:text-white dark:bg-neutral-800 gap-2 lg:gap-8 xl:gap-12 px-8 font-medium flex items-center justify-between">
                      <div className="w-32 text-sm ">Type</div>
                      <div className="text-sm flex items-center flex-grow gap-2">
                        Token
                      </div>
                      <div className="w-24 text-right text-sm ">Price</div>
                      <div className="w-24 text-right   text-sm hidden lg:block">
                        From
                      </div>
                      <div
                        className={`${
                          kind === "ask" ? "w-44" : "w-24"
                        } text-right text-sm`}
                      >
                        {kind === "ask" ? "Actions" : "To"}
                      </div>
                      <div className="w-32 hidden 2xl:block text-right text-sm">
                        Time
                      </div>
                    </div>

                    {activities?.map((activityItem: Activity) => {
                      return (
                        <ActivityItem
                          key={activityItem._id}
                          eventType={activityItem.kind}
                          tokenName={activityItem?.asset?.name}
                          tokenId={activityItem?.tokenId}
                          contractAddress={activityItem?.contractAddress}
                          tokenImage={activityItem?.asset?.imageThumbnailUrl}
                          priceCurrencySymbol={
                            activityItem.price?.currency?.symbol
                          }
                          price={activityItem.price?.amount?.decimal}
                          createdAt={
                            activityItem?.occurredAt
                              ? new Date(activityItem?.occurredAt)
                              : undefined
                          }
                          fromAddress={activityItem?.fromAddress}
                          fromAddressWalletInfo={
                            activityItem?.fromAddressWalletInfo
                          }
                          toAddress={activityItem?.toAddress}
                          toAddressWalletInfo={
                            activityItem?.toAddressWalletInfo
                          }
                          collectionId={activityItem?.collectionId}
                          collectionName={activityItem?.assetCollection?.name}
                          currentUserAddress={currentUserAddress}
                          txHash={activityItem.transactionHash}
                          newFloor={
                            !!activityItem.collectionFloorPriceReset
                              ?.newPriceInEth
                          }
                          assetRelistingCount={
                            activityItem?.assetRelistingCount
                          }
                        />
                      );
                    })}
                  </div>
                </InfiniteScroll>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="h-96 flex flex-col items-center justify-center">
      <p className="text-neutral-500">No assets with these filters</p>
    </div>
  );
};

export default CollectionActivity;
