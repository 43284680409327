import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const ColumnsTwo = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 19.7502H6.25C6.80229 19.7502 7.25 19.3025 7.25 18.7502V6.25C7.25 5.69772 6.80229 5.25 6.25 5.25H5.75C5.19772 5.25 4.75 5.69772 4.75 6.25V18.7502C4.75 19.3025 5.19772 19.7502 5.75 19.7502Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 19.7502H12.25C12.8023 19.7502 13.25 19.3025 13.25 18.7502V6.25C13.25 5.69772 12.8023 5.25 12.25 5.25H11.75C11.1977 5.25 10.75 5.69772 10.75 6.25V18.7502C10.75 19.3025 11.1977 19.7502 11.75 19.7502Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 19.7502H18.25C18.8023 19.7502 19.25 19.3025 19.25 18.7502V6.25C19.25 5.69772 18.8023 5.25 18.25 5.25H17.75C17.1977 5.25 16.75 5.69772 16.75 6.25V18.7502C16.75 19.3025 17.1977 19.7502 17.75 19.7502Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
