import { useInterval } from "usehooks-ts";
import ms from "ms";

interface AnalyticsTab {
  collection?: any;
}

const POLLING_INTERVAL_MINTS_MS = ms("30s");

const AnalyticsTab = ({ collection }: AnalyticsTab) => {
  const collectionId = collection?._id;
  const [owners, setOwners] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);
  const [totalListed, setTotalListed] = useState<number | null>(null);
  const [listingsTable, setListingsTable] = useState<any>(null);
  const [topOwnersOwned, setTopOwnersOwned] = useState<number | null>(null);
  const [topOwnersListed, setTopOwnersListed] = useState<number | null>(null);

  useEffect(() => {
    if (!collectionId) return;
    fetchFloorAssets();
    fetchOwners();
  }, [collectionId]);

  const fetchOwners = async () => {
    if (!collectionId) return;
    const res = await readCollectionOwners(collectionId);
    const topOwners = res.slice(0, 15);
    setOwners(topOwners);
  };

  useInterval(
    () => {
      fetchFloorAssets();
      fetchOwners();
    },
    // Delay in milliseconds or null to stop it
    POLLING_INTERVAL_MINTS_MS
  );

  useEffect(() => {
    if (!listingsTable || !owners) return;

    let ownedCount = 0;
    let listedCount = 0;
    owners?.map((item: any) => {
      let ownerAddress = item?._id;
      ownedCount += item.itemsOwned;
      if (ownerAddress in listingsTable) {
        listedCount += listingsTable[ownerAddress];
      }
    });

    setTopOwnersOwned(ownedCount);
    setTopOwnersListed(listedCount);
  }, [owners, listingsTable]);

  const fetchFloorAssets = async () => {
    let params = {
      filters: { onlyBuyNow: true, collectionId: collectionId },
      sort: "FLOOR_PRICE_ASC",
      limit: 200,
    };
    let response = await listAssets(params);

    // chart object
    let prices = [] as any;

    response.data.slice(0, 30).map((item: any) => {
      prices.push(item.floorAsk.price);
    });

    setChartData(prices);
    setTotalListed(response?.total);

    if (response?.data) {
      let listingsTable = buildListingCountMap(response.data);
      setListingsTable(listingsTable);
    }
  };

  const buildListingCountMap = (forSaleAssets: any) => {
    let listings = {} as any;

    // Loop through all listed assets
    forSaleAssets.map((asset: any, idx: number) => {
      let ownerAddress = asset?.ownerAddress?.toLowerCase();

      if (ownerAddress in listings) {
        // if ownerAddress is already in listings increment the count
        listings[ownerAddress] += 1;
      } else {
        // otherwise add the address and set to 1;
        listings[ownerAddress] = 1;
      }
    });

    return listings;
  };

  const renderTopCollectorsBar = () => {
    const totalSuppy = collection?.stats?.supplyTotal;

    if (
      topOwnersOwned === null ||
      topOwnersListed === null ||
      totalSuppy === null
    ) {
      return null;
    }

    const percentOwned = (topOwnersOwned / totalSuppy) * 100;
    const percentListed = (topOwnersListed / totalSuppy) * 100;
    const percentOwnedMinusListings = percentOwned - percentListed;

    return (
      <div className="border p-3 rounded-md dark:bg-neutral-800 dark:border-neutral-800">
        <div className="flex items-center gap-6 mb-3">
          <div className="flex items-center gap-1">
            <div className="h-3 w-3 rounded-sm dark:bg-neutral-200 bg-neutral-900"></div>
            <p className="text-xs">{topOwnersOwned} Owned</p>
          </div>
          <div className="flex items-center gap-1">
            <div
              style={{
                backgroundColor: "rgb(159 65 45)",
              }}
              className="h-3 w-3 rounded-sm"
            ></div>
            <p className="text-xs">{topOwnersListed} Listed</p>
          </div>
        </div>
        <div className="bg-neutral-200 dark:bg-neutral-700 flex w-full h-4 rounded-full overflow-hidden relative">
          <div
            id="ownedAmount"
            className="bg-neutral-900 dark:bg-neutral-200 h-4 border-r dark:border-neutral-900 border-neutral-200"
            style={{
              width: `${percentOwnedMinusListings.toFixed(2)}%`,
            }}
          ></div>
          <div
            id="listedAmount"
            style={{
              backgroundColor: "rgb(159 65 45)",
              width: `${percentListed.toFixed(2)}%`,
            }}
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-6">
      <p
        style={{ fontSize: 11, letterSpacing: 1 }}
        className="mt-8 text-neutral-900 dark:text-neutral-300 font-medium uppercase"
      >
        Floor Strength
      </p>
      <FloorDepthChart chartData={chartData} />
      <p
        style={{ fontSize: 11, letterSpacing: 1 }}
        className="mt-8 text-neutral-900 dark:text-neutral-300 font-medium uppercase mb-4"
      >
        Top 15 Collectors
      </p>
      <div className="mb-6">{renderTopCollectorsBar()}</div>
      <div className="flex flex-col gap-8 mb-8">
        {owners?.map((owner: any, idx: number) => {
          let ownerAddress = owner?._id?.toLowerCase();

          return (
            <div
              key={owner?._id}
              className="w-full flex items-center justify-between"
            >
              <div>
                <Link
                  href={`/wallets/${owner?._id}`}
                  className="hover:opacity-70 transition flex items-center gap-2 text-sm">

                  <div
                    className={`bg-stone-100 dark:bg-neutral-800 dark:text-white text-black w-8 h-8 flex flex-col items-center rounded-md justify-center font-medium`}
                  >
                    {idx + 1}
                  </div>
                  <div>
                    <p className="line-clamp-1">
                      <WalletLabel address={owner?._id} />
                    </p>
                    {listingsTable ? (
                      <>
                        {ownerAddress in listingsTable ? (
                          <p
                            className={`text-xs text-neutral-500 dark:text-neutral-400`}
                          >
                            {listingsTable[ownerAddress]} Listed
                          </p>
                        ) : (
                          <p
                            className={`text-xs text-neutral-500 dark:text-neutral-400`}
                          >
                            0 Listed
                          </p>
                        )}
                      </>
                    ) : (
                      <p
                        className={`w-12 anim-loading bg-loading-light dark:bg-loading-dark h-3 rounded-sm`}
                      ></p>
                    )}
                  </div>

                </Link>
              </div>
              <div className="flex flex-col text-sm justify-end text-right">
                <p>{owner?.itemsOwned} Items</p>
                <div className="flex items-center justify-end">
                  {collection && collection?.stats?.supplyTotal ? (
                    <p className="text-neutral-600 dark:text-neutral-400 text-xs">
                      {(
                        (owner?.itemsOwned / collection?.stats?.supplyTotal) *
                        100
                      ).toFixed(1)}
                      %
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { listAssets, readCollectionOwners } from "client/lib/api";
import { useEffect, useState } from "react";
import { Eth } from "../icons/eth";
import Link from "next/link";
import WalletLabel from "./WalletLabel";
import { Loader } from "../icons/loader";

const FloorDepthChart = ({ chartData }: { chartData: any }) => {
  const axisPadding = "10";
  const chartHeight = "64";
  const bottomThreshold = 0.05;
  const topPrice = chartData ? chartData[chartData.length - 1] : null;
  const bottomPrice = chartData ? chartData[0] : null;
  const bottomPlusThreshold = bottomPrice - bottomPrice * bottomThreshold;

  return (
    <div className={`flex h-${chartHeight}`}>
      <div className="flex flex-col flex-grow">
        <div className="bars flex-grow">
          <div className="h-full w-full flex">
            {chartData ? (
              <>
                {chartData.length > 0 ? (
                  <>
                    {chartData.map((price: any, idx: number) => {
                      const prevPrice = chartData[idx - 1]
                        ? chartData[idx - 1]
                        : bottomPlusThreshold;

                      const prevHeight =
                        ((prevPrice - bottomPlusThreshold) /
                          (topPrice - bottomPlusThreshold)) *
                        100;

                      const relativeHeight =
                        ((price - bottomPlusThreshold) /
                          (topPrice - bottomPlusThreshold)) *
                        100;

                      const cornerHeight = relativeHeight - prevHeight;

                      return (
                        <div
                          style={{
                            width: `${100 / chartData.length}%`,
                          }}
                          key={idx}
                          className="depth-bar h-full flex relative flex-col items-center justify-end"
                        >
                          <>
                            {/* Light version */}
                            <div className="w-full h-full dark:hidden flex flex-col items-center justify-end">
                              <div
                                style={{ width: 1 }}
                                className="flex-grow bg-neutral-200 mt-2 hover-bar opacity-0"
                              ></div>
                              <div
                                className="bar w-full border-neutral-400"
                                style={{
                                  height: `${cornerHeight.toFixed(2)}%`,
                                  width: "80%",
                                  background: `#b7b7b7`,
                                }}
                              ></div>
                              <div
                                className={`bar w-full`}
                                style={{
                                  height: `${prevHeight.toFixed(2)}%`,
                                  background: `#b7b7b7`,
                                  width: "80%",
                                }}
                              ></div>
                            </div>

                            {/* Dark version */}
                            <div className="hidden dark:flex w-full h-full flex-col items-center justify-end">
                              <div
                                style={{ width: 1 }}
                                className="flex-grow bg-neutral-700 mt-2 hover-bar opacity-0"
                              ></div>
                              <div
                                className="bar w-full"
                                style={{
                                  height: `${cornerHeight.toFixed(2)}%`,
                                  width: "80%",
                                  background: `#b7b7b7`,
                                }}
                              ></div>
                              <div
                                className="bar w-full"
                                style={{
                                  height: `${prevHeight.toFixed(2)}%`,
                                  background: `#b7b7b7`,
                                  width: "80%",
                                }}
                              ></div>
                            </div>
                          </>
                          <div
                            id="floorDepthTooltip"
                            className={`${idx < 5 ? "left-4" : null} ${
                              idx > 25 ? "right-4" : null
                            } bg-white p-3 dark:bg-neutral-800 dark:border-neutral-700 bg-white w-32 rounded-md z-10 hidden invisible opacity-0 transition-all border absolute -bottom-28`}
                          >
                            <p
                              style={{ fontSize: 11, letterSpacing: 1 }}
                              className="text-neutral-500 dark:text-neutral-400 font-medium uppercase"
                            >
                              Price
                            </p>
                            <div className="flex items-center text-sm text-left">
                              <Eth
                                size={12}
                                style={{ marginRight: 2 }}
                                className="text-neutral-500 dark:text-neutral-400"
                              />
                              {price}
                            </div>
                            <p
                              style={{ fontSize: 11, letterSpacing: 1 }}
                              className="mt-1 text-neutral-500 font-medium uppercase"
                            >
                              Until Price
                            </p>
                            <p className="text-sm">
                              {idx === 0 ? "Current Floor" : `${idx} Items`}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="h-full w-full py-3">
                    <div className="bg-neutral-100 dark:bg-neutral-800 dark:text-white rounded-md h-full w-full flex flex-col items-center justify-center text-center">
                      <p className="text-sm mb-1">No listings</p>
                      <p className="px-4 text-xs text-neutral-500 dark:text-neutral-400">
                        This chart will populate once this collection has
                        listings
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <Loader className="animate-spin text-neutral-400 dark:text-neutral-300" />
              </div>
            )}
          </div>
        </div>
        <div
          id="xAxis"
          className={`border-t dark:border-neutral-500 flex text-sm dark:text-neutral-300 text-neutral-500 justify-between h-${axisPadding}`}
        >
          <div className="flex items-center text-left text-neutral-700 dark:text-neutral-200">
            <Eth
              size={12}
              style={{ marginRight: 2 }}
              className="text-neutral-700 dark:text-neutral-300"
            />
            {bottomPrice ? bottomPrice : "-"}
          </div>
          <div className="flex items-center text-right text-neutral-700 dark:text-neutral-200">
            <Eth
              size={12}
              style={{ marginRight: 2 }}
              className="text-neutral-700 dark:text-neutral-300"
            />
            {topPrice ? topPrice : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTab;
