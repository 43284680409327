import { ReactNode, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

export function Portal({ children }: { children: ReactNode }) {
  const [mounted, setMounted] = useState(false);
  useLayoutEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  return mounted ? <>{createPortal(<>{children}</>, document.body)}</> : null;
}
