export const findLowestEntry = (collectionList: any) => {
  let lowestEntry = collectionList[0].stats?.floorPrice;

  for (let i = 0; i < collectionList.length; i++) {
    let collection = collectionList[i];
    let floorPrice = collection?.stats?.floorPrice;

    if (floorPrice < lowestEntry || !lowestEntry) {
      lowestEntry = floorPrice;
    }
  }

  return lowestEntry;
};

export const findTotalVolume = (collectionList: any) => {
  let volume = 0;

  for (let i = 0; i < collectionList.length; i++) {
    let collection = collectionList[i];
    if (typeof collection?.stats?.volumeTotal !== "number") {
      continue;
    }
    volume += collection?.stats?.volumeTotal;
  }

  return volume;
};

export const findTotalPieces = (collectionList: any) => {
  let supply = 0;

  for (let i = 0; i < collectionList.length; i++) {
    let collection = collectionList[i];
    supply += collection?.stats?.supplyTotal;
  }

  return supply;
};
