import React from "react";

import { ProjectSaleManagerMachineContext } from "../../ProjectSaleManagerMachineContext";
import Image from "next/image";
import { cloudinaryLoader } from "@/client/lib/helpers";

export function CollectionOverviewTokenDisplay({
  fallbackTokenImageUrl,
}: {
  fallbackTokenImageUrl?: string;
}) {
  const artblocksProject = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.project;
    }
  );

  const featuredImageUrl =
    artblocksProject?.featured_token?.[0]?.media_url || fallbackTokenImageUrl;

  // can only use cloudinary loader if loading project image from cloudinary. The fallback image may not be from cloudinary
  const useCloudinaryLoader =
    !!artblocksProject?.featured_token?.[0]?.media_url;

  // if we using the cloudinary loader we can use next/image otherwise we
  // fallback to img because we dont know if the image domain is whitelisted by next/image
  const featuredImage = useCloudinaryLoader ? (
    <Image
      src={featuredImageUrl ?? ""}
      alt="featured token"
      loader={(props) => cloudinaryLoader(false, props)}
      placeholder={"empty"}
      className="object-contain w-full h-full"
      fill
      // 640 is the default sm breakpoint for tailwind
      sizes={"(min-width: 640px) 30vw, 100vw"}
    />
  ) : (
    <img
      src={featuredImageUrl ?? ""}
      alt="featured token"
      sizes={"(min-width: 640px) 30vw, 100vw"}
      className="object-contain w-full h-full"
    />
  );

  return (
    <div className="relative w-full h-96 bg-neutral-100 dark:bg-neutral-800">
      {featuredImageUrl ? featuredImage : null}
    </div>
  );
}
