import { cn } from "@/client/lib/classnames";
import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const ErrorIcon = ({ size = 24, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("text-red-500", className)}
      {...props}
    >
      <path
        d="M8.7085 22C8.7085 14.6592 14.6594 8.70834 22.0002 8.70834C29.3409 8.70834 35.2918 14.6592 35.2918 22C35.2918 29.3408 29.3409 35.2917 22.0002 35.2917C14.6594 35.2917 8.7085 29.3408 8.7085 22Z"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.875 17.875L26.125 26.125"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.125 17.875L17.875 26.125"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
