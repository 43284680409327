import { Minter_Type_Names_Enum } from "@/gql/graphql";
import { useEffect, useState } from "react";
import {
  formatUnits,
  isAddress,
  zeroAddress,
  erc20Abi,
  formatEther,
} from "viem";
import { useReadContract } from "wagmi";

// Helper hook to avoid hydration issue due to timezone difference
export function useFormattedProjectDate(startDate?: Date | null) {
  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  useEffect(() => {
    setFormattedDate(formatProjectDate(startDate));
  }, [startDate]);

  return formattedDate;
}

type PriceDisplay = {
  priceDisplay: string | null;
  currencySymbol: string | null;
};
// helper hook to format price display
// accepts all nullable args, and assumes priced in ETH if currencyAddress is null or zero address
export function usePriceDisplay(
  tokenPriceInWei?: bigint | null,
  currencyAddress?: string | null,
  currencySymbol?: string | null
): PriceDisplay {
  const erc20Decimals = useReadContract({
    address:
      (currencyAddress as "0x${string}") || (zeroAddress as "0x${string}"),
    abi: erc20Abi,
    functionName: "decimals",
  });

  // if no price, display '--'
  if (tokenPriceInWei === undefined || tokenPriceInWei === null) {
    return {
      priceDisplay: "--",
      currencySymbol: null,
    };
  }
  // handle price is in ETH
  if (!currencyAddress || currencyAddress === zeroAddress) {
    return {
      priceDisplay: Number(formatEther(tokenPriceInWei)).toFixed(4),
      currencySymbol: "ETH",
    };
  }
  // handle price is in ERC20 and we have symbol and decimals
  if (
    currencyAddress &&
    isAddress(currencyAddress || "") &&
    !!currencySymbol &&
    !!erc20Decimals?.data
  ) {
    return {
      priceDisplay: Number(
        formatUnits(tokenPriceInWei, erc20Decimals?.data)
      ).toFixed(2),
      currencySymbol: currencySymbol,
    };
  }

  // unhandled case, display '--'
  return {
    priceDisplay: "--",
    currencySymbol: null,
  };
}

export function formatProjectDate(startDate?: Date | null) {
  if (!startDate) return null;

  const formattedStartDateParts = Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    weekday: "short",
  }).formatToParts(startDate);
  const weekday = formattedStartDateParts?.find(
    (part) => part.type === "weekday"
  )?.value;
  const month = formattedStartDateParts?.find(
    (part) => part.type === "month"
  )?.value;
  const day = formattedStartDateParts?.find(
    (part) => part.type === "day"
  )?.value;
  const hour = formattedStartDateParts?.find(
    (part) => part.type === "hour"
  )?.value;
  const minute = formattedStartDateParts?.find(
    (part) => part.type === "minute"
  )?.value;
  const dayPeriod = formattedStartDateParts?.find(
    (part) => part.type === "dayPeriod"
  )?.value;

  return `${weekday}, ${month} ${day}, ${hour}:${minute}${
    dayPeriod ? ` ${dayPeriod}` : ""
  }`;
}

const SETTLEMENT_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterDaExpSettlementV3,
];
export function isSettlementMinterType(
  minterType: Minter_Type_Names_Enum | undefined
) {
  return SETTLEMENT_MINTER_TYPES.includes(minterType);
}

const AUCTION_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterDaExpSettlementV3,
  Minter_Type_Names_Enum.MinterDaExpHolderV5,
  Minter_Type_Names_Enum.MinterDaLinHolderV5,
  Minter_Type_Names_Enum.MinterDaLinV5,
  Minter_Type_Names_Enum.MinterDaExpV5,
];
export function isAuctionMinterType(
  minterType: Minter_Type_Names_Enum | undefined
) {
  return AUCTION_MINTER_TYPES.includes(minterType);
}

const ALLOWLIST_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterSetPriceMerkleV5,
  Minter_Type_Names_Enum.MinterSetPriceHolderV5,
  Minter_Type_Names_Enum.MinterDaExpHolderV5,
  Minter_Type_Names_Enum.MinterDaLinHolderV5,
  Minter_Type_Names_Enum.MinterMinPriceMerkleV0,
];
export function isAllowlistMinterType(
  minterType: Minter_Type_Names_Enum | undefined
) {
  return ALLOWLIST_MINTER_TYPES.includes(minterType);
}

const SET_PRICE_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterSetPriceV5,
  Minter_Type_Names_Enum.MinterSetPriceMerkleV5,
  Minter_Type_Names_Enum.MinterSetPriceHolderV5,
  Minter_Type_Names_Enum.MinterMinPriceV0,
  Minter_Type_Names_Enum.MinterMinPriceMerkleV0,
];
export function isSetPriceMintertype(
  minterType: Minter_Type_Names_Enum | undefined
) {
  return SET_PRICE_MINTER_TYPES.includes(minterType);
}

const HOLDER_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterSetPriceHolderV5,
  Minter_Type_Names_Enum.MinterDaExpHolderV5,
  Minter_Type_Names_Enum.MinterDaLinHolderV5,
];
function isHolderMinterType(minterType: Minter_Type_Names_Enum | undefined) {
  return HOLDER_MINTER_TYPES.includes(minterType);
}

const MERKLE_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterSetPriceMerkleV5,
  Minter_Type_Names_Enum.MinterMinPriceMerkleV0,
];
function isMerkleMinterType(minterType: Minter_Type_Names_Enum | undefined) {
  return MERKLE_MINTER_TYPES.includes(minterType);
}

const ERC20_MINTER_TYPES: Array<Minter_Type_Names_Enum | undefined> = [
  Minter_Type_Names_Enum.MinterSetPriceErc20V5,
];

export function isERC20MinterType(
  minterType: Minter_Type_Names_Enum | undefined
) {
  return ERC20_MINTER_TYPES.includes(minterType);
}

export function getConciseMinterLabel(
  minterType: Minter_Type_Names_Enum | undefined
) {
  if (!minterType) {
    return "--";
  }

  if (isSetPriceMintertype(minterType) && isHolderMinterType(minterType)) {
    return "Token-gated auction";
  }

  if (isSetPriceMintertype(minterType) && isMerkleMinterType(minterType)) {
    return "Allowlist auction";
  }

  if (isSetPriceMintertype(minterType)) {
    return "Fixed price auction";
  }

  if (isAuctionMinterType(minterType) && isHolderMinterType(minterType)) {
    return "Token-gated descending auction without settlement";
  }

  if (isAuctionMinterType(minterType) && isSettlementMinterType(minterType)) {
    return "Descending auction with settlement";
  }

  if (isAuctionMinterType(minterType)) {
    return "Descending auction without settlement";
  }

  if (isERC20MinterType(minterType)) {
    return "Auction priced in an ERC-20 token";
  }

  return "Custom minting";
}
