import { useState } from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";

export default function Switch({
  enabled,
  setEnabled,
  size = "normal",
}: {
  enabled: boolean;
  setEnabled: any;
  size?: string;
}) {
  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={setEnabled}
      className={`${
        enabled
          ? "bg-neutral-900 dark:bg-white"
          : "bg-neutral-200 dark:bg-neutral-700"
      }
          relative inline-flex ${
            size === "normal" ? "h-[32px] w-[64px]" : "h-[28px] w-[60px]"
          } shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? "translate-x-8" : "translate-x-0"}
            pointer-events-none inline-block ${
              size === "normal" ? "h-[28px] w-[28px]" : "h-[24px] w-[24px]"
            } transform rounded-full bg-white dark:bg-neutral-900 shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </HeadlessSwitch>
  );
}
