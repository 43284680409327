import { useState, useEffect } from "react";

import { Chevron } from "../icons/chevron";
import AnalyticsTab from "./AnalyticsTab";
import ArtistTab from "./ArtistTab";

const RightSidebar = ({
  open,
  setOpen,
  collection,
}: {
  open: boolean;
  setOpen: any;
  collection: any;
}) => {
  const [currentTab, setCurrentTab] = useState<string>("artist");

  const navigation = {} as any;

  // setup tabs that are always visible
  navigation["analytics"] = "Analytics";
  navigation["artist"] = "Artist";

  useEffect(() => {
    if (!collection) return;

    setCurrentTab("analytics");
  }, [collection]);

  const returnTabContent = () => {
    switch (currentTab) {
      case "artist":
        return <ArtistTab collection={collection} />;
      case "analytics":
        return <AnalyticsTab collection={collection} />;
    }
  };

  return (
    <div
      style={{ height: "calc(100vh - 80px)" }}
      className={`border-l xl:block hidden transition-all duration-300 dark:bg-neutral-900 dark:text-white dark:border-neutral-700 sticky top-20 bg-white flex-shrink-0 ${
        open ? "w-80 overflow-y-auto" : "w-22"
      }`}
    >
      <div
        className={` ${
          open
            ? "border-b dark:border-neutral-700 pl-6 justify-between"
            : "justify-center mt-3"
        } flex items-center`}
      >
        {open ? (
          <div className="flex items-center text-sm gap-9">
            {Object.keys(navigation).map((key: string) => {
              let tabTitle = navigation[key];
              return (
                <button
                  style={{ height: 56 }}
                  className={`${
                    currentTab === key
                      ? "text-black border-b-2 dark:text-white dark:border-white border-black"
                      : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-300"
                  }`}
                  key={key}
                  onClick={() => setCurrentTab(key)}
                >
                  {tabTitle}
                </button>
              );
            })}
          </div>
        ) : null}
        <div
          className={`${
            open
              ? "border-l dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800"
              : "bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 dark:hover:bg-neutral-800"
          }  transition flex flex-col items-center flex-shrink-0 justify-center w-14 h-14`}
        >
          <button
            className="flex flex-col items-center justify-center w-full h-full"
            onClick={() => setOpen((prev: boolean) => !prev)}
          >
            <Chevron
              size={24}
              className={`transform transition ${
                open ? "-rotate-90" : "rotate-90"
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className={`${
          open
            ? "opacity-100 visible delay-200 duration-500"
            : "opacity-0 invisible"
        } transition-all`}
      >
        {collection ? <>{returnTabContent()}</> : null}
      </div>
    </div>
  );
};

export default RightSidebar;
