import { purchaseTrackingManagerMachineContext } from "@/client/components/common/PurchaseTrackingManagerMachineContext";
import { useSyncMachineWithArtBlocksClient } from "@/client/components/hooks/useSyncMachineWithWeb3Provider";
import { projectSaleManagerMachine } from "@artblocks/sdk/dist/machines/project-sale-manager-machine";
import { createBrowserInspector } from "@/client/lib/helpers";
import { createActorContext, useSelector } from "@xstate/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SnapshotFrom, ActorRefFrom, createEmptyActor } from "xstate";
import { useArtBlocksClient } from "@/client/components/common/ArtBlocksProvider";
import { purchaseInitiationMachine } from "@artblocks/sdk/dist/machines/purchase-initiation-machine";
import { purchaseTrackingMachine } from "@artblocks/sdk/dist/machines/purchase-tracking-machine";
import { Hex } from "viem";

const { inspect } = createBrowserInspector();

export const ProjectSaleManagerMachineContext = createActorContext(
  projectSaleManagerMachine
);

export function ProjectSaleManagerMachineContextProvider({
  artblocksProjectId,
  snapshot,
  children,
}: {
  artblocksProjectId?: string;
  snapshot?: SnapshotFrom<typeof projectSaleManagerMachine>;
  children: React.ReactNode;
}) {
  const artblocksClient = useArtBlocksClient();

  return (
    <ProjectSaleManagerMachineContext.Provider
      options={{
        snapshot: snapshot,
        input: {
          projectId: artblocksProjectId,
          artblocksClient: artblocksClient,
        },
        inspect,
      }}
    >
      <PurchaseTrackingMachineProvider>
        <ProjectSaleManagerMachineManager />
        {children}
      </PurchaseTrackingMachineProvider>
    </ProjectSaleManagerMachineContext.Provider>
  );
}

function ProjectSaleManagerMachineManager() {
  const projectSaleManagerMachineRef =
    ProjectSaleManagerMachineContext.useActorRef();
  useSyncMachineWithArtBlocksClient(projectSaleManagerMachineRef);

  const purchaseTrackingManagerMachineRef =
    purchaseTrackingManagerMachineContext.useActorRef();
  useEffect(() => {
    projectSaleManagerMachineRef.send({
      type: "PURCHASE_TRACKING_MANAGER_MACHINE_AVAILABLE",
      data: purchaseTrackingManagerMachineRef,
    });
  }, [purchaseTrackingManagerMachineRef, projectSaleManagerMachineRef]);

  return null;
}

export function useLiveSaleData() {
  const liveSaleData = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.liveSaleData;
    },
    (a, b) => {
      return stringifyWithBigInts(a) === stringifyWithBigInts(b);
    }
  );

  return liveSaleData;
}

export function usePurchaseInitiationMachine():
  | {
      state: SnapshotFrom<typeof purchaseInitiationMachine>;
      ref: ActorRefFrom<typeof purchaseInitiationMachine>;
    }
  | { state: undefined; ref: undefined } {
  const purchaseInitiationMachineRef =
    ProjectSaleManagerMachineContext.useSelector(
      (state) => state.context.purchaseInitiationMachine
    );

  const purchaseInitiationMachineState = useSelector(
    purchaseInitiationMachineRef ?? createEmptyActor(),
    (state) => {
      if ("value" in state) {
        return state;
      }

      return null;
    }
  );

  if (!purchaseInitiationMachineState || !purchaseInitiationMachineRef) {
    return {
      state: undefined,
      ref: undefined,
    };
  }

  return {
    state: purchaseInitiationMachineState,
    ref: purchaseInitiationMachineRef,
  };
}

type PurchaseTrackingMachineContextType =
  | {
      state: SnapshotFrom<typeof purchaseTrackingMachine> | undefined;
      ref: ActorRefFrom<typeof purchaseTrackingMachine> | undefined;
      reset: () => void;
    }
  | {
      state: undefined;
      ref: undefined;
      reset: undefined;
    };

const PurchaseTrackingMachineContext = createContext<
  PurchaseTrackingMachineContextType | undefined
>(undefined);

export function PurchaseTrackingMachineProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { ref: purchaseInitiationMachineRef } = usePurchaseInitiationMachine();
  const [purchaseTxHash, setPurchaseTxHash] = useState<Hex | undefined>();

  const reset = useCallback(() => {
    setPurchaseTxHash(undefined);
  }, []);

  useEffect(() => {
    purchaseInitiationMachineRef?.on("purchaseInitiated", (event) => {
      setPurchaseTxHash(event.txHash);
    });
  }, [purchaseInitiationMachineRef]);

  const purchaseTrackingMachineRef =
    purchaseTrackingManagerMachineContext.useSelector(
      (state) => {
        return state.context.purchaseTrackingMachines[purchaseTxHash ?? ""];
      },
      (a, b) => {
        return a && b && Object.keys(a).join() === Object.keys(b).join();
      }
    );

  const purchaseTrackingMachineState = useSelector(
    purchaseTrackingMachineRef ?? createEmptyActor(),
    (state) => {
      if ("value" in state) {
        return state;
      }
      return undefined;
    }
  );

  const contextValue: PurchaseTrackingMachineContextType = {
    state: purchaseTrackingMachineState,
    ref: purchaseTrackingMachineRef,
    reset,
  };

  return (
    <PurchaseTrackingMachineContext.Provider value={contextValue}>
      {children}
    </PurchaseTrackingMachineContext.Provider>
  );
}

export function usePurchaseTrackingMachine(): PurchaseTrackingMachineContextType {
  const context = useContext(PurchaseTrackingMachineContext);
  if (context === undefined) {
    throw new Error(
      "usePurchaseTrackingMachine must be used within a PurchaseTrackingMachineProvider"
    );
  }
  return context;
}

function stringifyWithBigInts(value: any) {
  return JSON.stringify(value, (_, v) =>
    typeof v === "bigint" ? v.toString() : v
  );
}
