import { useSelector } from "@xstate/react";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";
import { Hex, formatUnits, isAddress } from "viem";
import { ActorRefFrom } from "xstate";

import { Portal } from "@/client/components/common/Portal";
import Button from "@/client/components/frame-design-system/buttons/Button";
import Input from "@/client/components/frame-design-system/inputs/Input";
import { Edit } from "@/client/components/icons/edit";
import { Ethereum } from "@/client/components/icons/ethereum";
import { DEFAULT_CRYPTO_DECIMAL_PRECISION } from "@/client/lib/numbers";
import { purchaseInitiationMachine } from "@artblocks/sdk/dist/machines/purchase-initiation-machine";

import { ProjectSaleManagerMachineContext } from "../../ProjectSaleManagerMachineContext";

export function PurchaseForm({
  purchaseInitiationMachineRef,
}: {
  purchaseInitiationMachineRef: ActorRefFrom<typeof purchaseInitiationMachine>;
}) {
  const purchaseInitiationState = useSelector(
    purchaseInitiationMachineRef,
    (state) => {
      return state.value;
    }
  );
  const decimals = useSelector(purchaseInitiationMachineRef, (state) => {
    return (
      state.context.additionalPurchaseData?.decimals ??
      DEFAULT_CRYPTO_DECIMAL_PRECISION
    );
  });

  const project = ProjectSaleManagerMachineContext.useSelector((state) => {
    return state.context.project;
  });

  const price = ProjectSaleManagerMachineContext.useSelector((state) => {
    if (!state.context.liveSaleData) {
      return null;
    }

    return formatUnits(state.context.liveSaleData.tokenPriceInWei, decimals);
  });

  const connectedWalletAddress = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.artblocksClient.getWalletClient()?.account?.address;
    }
  );

  const publicClient = ProjectSaleManagerMachineContext.useSelector((state) =>
    state.context.artblocksClient.getPublicClient()
  );

  const [error, setError] = useState<string | null>("Required");
  const [touched, setTouched] = useState(false);
  const [showPurchaseTo, setShowPurchaseTo] = useState(false);
  const [purchaseToAddress, setPurchaseToAddress] = useState<Hex | null>(null);

  const truncatedWalletAddress = `${(
    purchaseToAddress ?? connectedWalletAddress
  )?.slice(0, 5)}...`;
  const purchasingToConnectedWallet = !purchaseToAddress;

  const validateAddressOrEns = useCallback(
    async (address: string) => {
      let resolvedAddress: string | null = null;

      if (!isAddress(address) && publicClient) {
        resolvedAddress = await publicClient.getEnsAddress({
          name: address ?? "",
          blockNumber: undefined,
          blockTag: undefined,
        });

        if (!resolvedAddress) {
          setError(
            "The provided value is not a valid Ethereum address or ENS name."
          );
          return {
            valid: false,
            resolvedAddress: null,
          };
        }
      }

      setError(null);
      return {
        valid: true,
        resolvedAddress: resolvedAddress,
      };
    },
    [publicClient]
  );

  const handlePurchaseToAddressUpdate = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setTouched(true);

      const formData = new FormData(e.currentTarget);
      const purchaseToAddress = formData.get("purchaseToAddress") as string;
      const result = await validateAddressOrEns(purchaseToAddress);

      if (!result.valid) {
        return;
      }

      const address = result.resolvedAddress ?? purchaseToAddress;

      // If the user is trying to set the address to their connected wallet
      // address, we should clear the field and show the connected wallet
      if (address.toLowerCase() === connectedWalletAddress?.toLowerCase()) {
        setPurchaseToAddress(null);
      } else {
        setPurchaseToAddress(address as Hex);
      }

      setShowPurchaseTo(false);
      setTouched(false);
    },
    [validateAddressOrEns, connectedWalletAddress]
  );

  // Note: No need to validate input here because we don't allow purchaseToAddress
  // to be set unless it's a valid address or ENS name. The connected wallet should
  // always be valid.
  const handlePurchase = useCallback(async () => {
    if (
      purchaseInitiationState === "gettingUserPurchaseEligibilityAndContext" ||
      purchaseInitiationState === "initiatingPurchase"
    ) {
      return;
    }

    purchaseInitiationMachineRef.send({
      type: "INITIATE_PURCHASE",
      purchaseToAddress: purchaseToAddress ?? undefined,
    });
  }, [
    purchaseInitiationState,
    purchaseToAddress,
    purchaseInitiationMachineRef,
  ]);

  // The only field necessary is the address field and we only need to validate
  // it if the user is entering an address other than their connected wallet
  // address.
  const formValid = !showPurchaseTo && (!purchaseToAddress || !error);

  // createPortal fixes a known CSS issue with fixed position elements. This button is meant to sit at
  // the bottom of the screen on mobile, but the parent Modal component uses "transform" for animations,
  // which causes fixed position elements to be positioned relative to the parent element instead of the
  // viewport. createPortal is used to render the button outside of the parent element, fixing the issue.
  const mobilePurchaseBanner = (
    <Portal>
      <div
        className={`md:hidden z-[60] fixed w-full h-[130px] bg-white dark:bg-black dark:text-white px-4 py-2 m-0 inset-x-0 bottom-0 rounded-none drop-shadow-xl flex flex-col justify-between`}
      >
        <div className="flex justify-between py-1">
          <p className="text-p-lg">Total</p>
          <p className="flex items-center text-p-lg">
            <Ethereum />
            {price}
          </p>
        </div>
        <Button
          disabled={!formValid}
          type="button"
          onClick={handlePurchase}
          className="disabled:opacity-60 my-3"
          size="large"
        >
          Purchase
        </Button>
      </div>
    </Portal>
  );

  return (
    // add space to prevent the floating purchase banner on mobile from covering the content
    <div className="mb-[130px] md:mb-0">
      <div className="py-4 border-b border-black dark:border-white border-opacity-10 dark:border-opacity-10">
        <p className="text-lg md:text-p-lg">{project?.name}</p>
        <p className="text-p-m opacity-60">{project?.artist_name}</p>
      </div>
      {!showPurchaseTo ? (
        <div className="flex flex-col md:flex-row justify-between py-4 space-y-2 md:space-y-0 md:border-b border-black dark:border-white border-opacity-10 dark:border-opacity-10">
          <p className="text-p-lg">Delivery Address</p>
          <button
            className="flex items-center gap-1 text-p-lg"
            onClick={() => {
              setError("Required");
              setShowPurchaseTo(true);
            }}
          >
            <span>
              {truncatedWalletAddress}
              {purchasingToConnectedWallet ? " (Connected)" : ""}
            </span>
            <Edit size={24} className="opacity-60" />
          </button>
        </div>
      ) : (
        <form
          onSubmit={handlePurchaseToAddressUpdate}
          className="py-4 md:border-b border-black dark:border-white border-opacity-10 dark:border-opacity-10"
        >
          <p className="mb-2 text-p-lg">Delivery Address</p>
          <div className="flex flex-col md:flex-row items-center mb-2 md:space-x-2 space-y-2 md:space-y-0">
            <Input
              name="purchaseToAddress"
              placeholder="Enter address or ENS"
              defaultValue={purchaseToAddress ?? ""}
              onChange={debounce((e) => {
                setTouched(true);
                validateAddressOrEns(e.target.value);
              }, 500)}
              className="flex-1 md:mr-2 w-full"
            />
            <div className="flex space-x-2 w-full">
              <Button
                size="medium"
                type="submit"
                variant="secondary"
                className="w-1/2 md:w-full"
              >
                Update
              </Button>
              <Button
                size="medium"
                className="w-1/2 md:w-full"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setError(null);
                  setTouched(false);
                  setShowPurchaseTo(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
          {touched && error ? (
            <p className="text-red-600 text-p-xs">{error}</p>
          ) : null}
        </form>
      )}
      {mobilePurchaseBanner}
      <div className="hidden md:block">
        <div className="flex justify-between py-4 mb-4 border-b border-black dark:border-white border-opacity-10 dark:border-opacity-10">
          <p className="text-p-lg">Total</p>
          <p className="flex items-center text-p-lg">
            {project?.minter_configuration?.currency_symbol === "ETH" ? (
              <Ethereum />
            ) : (
              `${project?.minter_configuration?.currency_symbol} `
            )}
            {price}
          </p>
        </div>
        <Button
          disabled={!formValid}
          type="button"
          onClick={handlePurchase}
          className="w-full disabled:opacity-60"
          size="large"
        >
          Purchase
        </Button>
      </div>
    </div>
  );
}
