import Switch from "client/components/common/Switch";
import TraitSelector from "../../../common/TraitSelector/TraitSelector";
import { Filters as FilterIcon } from "client/components/icons/filters";
import LeftSidebarContainer from "client/components/common/Layout/LeftSidebar/LeftSidebarContainer";
import LeftSidebarBody from "client/components/common/Layout/LeftSidebar/LeftSidebarBody";
import LeftSidebarHeader from "client/components/common/Layout/LeftSidebar/LeftSidebarHeader";
import SelectBox from "client/components/common/SelectBox";
import Tag from "client/components/icons/tag";
import { ShoppingBag } from "client/components/icons/shopping-bag";
import Button from "@/client/components/frame-design-system/buttons/Button";
import { Filter } from "@/client/components/icons/filter";
import { getBasePath } from "@/shared/config";

interface FilterSidebar {
  open: boolean;
  setOpen: any;
  onlyBuyNow: boolean;
  toggleBuyNow: () => void;
  onlySansa: boolean;
  toggleOnlySansa: () => void;
  traits: any;
  selectedTraits: any;
  addTraitFilter: any;
  removeTraitFilter: any;
  customTraits: any;
  selectedCustomTraits: any;
  addCustomTraitFilter: any;
  removeCustomTraitFilter: any;
  activityFilters: any;
  toggleActivityFilter: any;
  currentTab: string;
  exploreTrait: any;
  selectedExploreTrait: any;
  showRankings: boolean;
  setShowRankings: any;
  disableRankings?: boolean;
}

const FilterSidebar = ({
  open,
  setOpen,
  onlyBuyNow,
  toggleBuyNow,
  onlySansa,
  toggleOnlySansa,
  traits,
  selectedTraits,
  addTraitFilter,
  removeTraitFilter,
  selectedCustomTraits,
  customTraits,
  addCustomTraitFilter,
  removeCustomTraitFilter,
  activityFilters,
  toggleActivityFilter,
  currentTab,
  exploreTrait,
  selectedExploreTrait,
  showRankings,
  setShowRankings,
  disableRankings,
}: FilterSidebar) => {
  return (
    <LeftSidebarContainer open={open}>
      <LeftSidebarBody open={open}>
        <div className="pt-6 md:hidden">
          <Button
            title="Hide Filters"
            variant="secondary"
            className="w-full"
            onClick={() => setOpen(false)}
          >
            <Filter />
            <span>Hide Filters</span>
          </Button>
        </div>
        {currentTab === "items" ? (
          <>
            <div className="flex items-center justify-between py-2 mt-8">
              <p>Buy Now</p>
              <Switch enabled={onlyBuyNow} setEnabled={() => toggleBuyNow()} />
            </div>
            <div className="flex items-center justify-between py-2 mt-4">
              <div className="flex items-center">
                <img
                  className="w-8 h-8 mr-2"
                  src={`${getBasePath()}/market-icon.svg`}
                />
                <p>Only Art Blocks</p>
              </div>
              <Switch
                enabled={onlySansa}
                setEnabled={() => toggleOnlySansa()}
              />
            </div>

            {disableRankings ? null : (
              <div className="flex items-center justify-between py-2 mt-4">
                <p>Rarity Rankings</p>
                <Switch
                  enabled={disableRankings ? false : showRankings}
                  setEnabled={
                    disableRankings
                      ? () => {}
                      : () => setShowRankings((prev: boolean) => !prev)
                  }
                />
              </div>
            )}

            <p className="mt-8 mb-6 font-medium">Traits</p>
            {traits ? (
              <>
                {traits.length > 0 ? (
                  <>
                    {traits.map((trait: any) => {
                      return (
                        <TraitSelector
                          key={trait.traitType}
                          traitKey={trait.traitType}
                          traitKind={trait.kind}
                          traitValues={trait.values}
                          selectedTraits={selectedTraits}
                          addTraitFilter={addTraitFilter}
                          removeTraitFilter={removeTraitFilter}
                          exploreTrait={exploreTrait}
                          selectedExploreTrait={selectedExploreTrait}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p className="p-3 text-sm rounded-md bg-neutral-100 dark:bg-neutral-800">
                      No traits for this collection
                    </p>
                  </>
                )}
              </>
            ) : (
              <div className="h-12 rounded-md anim-loading bg-loading-light dark:bg-loading-dark"></div>
            )}
            {customTraits.length > 0 ? (
              <>
                <p className="mt-8 mb-6 font-medium">Community Traits</p>
                {customTraits?.map((trait: any) => {
                  return (
                    <TraitSelector
                      key={trait.traitType}
                      traitKey={trait.traitType}
                      traitValues={trait.values}
                      selectedTraits={selectedCustomTraits}
                      addTraitFilter={addCustomTraitFilter}
                      removeTraitFilter={removeCustomTraitFilter}
                    />
                  );
                })}
              </>
            ) : null}
          </>
        ) : (
          <>
            <p className="mt-8 mb-6 font-medium">Event Types</p>
            {activityFilters ? (
              <div className="flex flex-col gap-6">
                {activityFilters.map((item: any) => {
                  return (
                    <label key={item.value} htmlFor={item.value}>
                      <SelectBox
                        title={item.name}
                        icon={
                          item?.name === "Listing" ? (
                            <Tag size={20} />
                          ) : (
                            <ShoppingBag size={20} />
                          )
                        }
                        onChange={(e: any) => {
                          toggleActivityFilter(item.value);
                        }}
                        isSelected={item.selected}
                        bordered={true}
                      />
                    </label>
                  );
                })}
              </div>
            ) : null}
          </>
        )}
      </LeftSidebarBody>
    </LeftSidebarContainer>
  );
};

export default FilterSidebar;
