import Link from "next/link";
import { useEffect, useState, useRef, useMemo } from "react";
import Markdown from "react-markdown";

import { ArtistItem } from "@/client/components/common/ArtistItem";
import { HeaderStatItem } from "@/client/components/common/HeaderStatItem";
import Avatar from "@/client/components/frame-design-system/avatars/Avatar";
import Button from "@/client/components/frame-design-system/buttons/Button";
import EthLabel from "@/client/components/frame-design-system/labels/EthLabel";
import { Sparkles } from "@/client/components/icons/sparkles";
import { listAssets } from "@/client/lib/api";
import { numberWithCommas } from "@/client/lib/helpers";
import { getEtherscanWalletUrl } from "@/client/lib/links";
import { getBasePath } from "@/shared/config";
import { useFormattedDate } from "@/client/components/hooks/useLocalizedDate";

interface CollectionHeader {
  id: string;
  slug?: string;
  name?: string;
  image?: string;
  contractAddress?: string;
  description?: string;
  floorPrice?: number;
  volume?: number;
  supply?: number;
  owners?: number;
  loading?: boolean;
  enableAlgoExplore?: boolean;
  artistIds?: string[];
  platformData?: any;
  artistDisplayNotes?: string;
  creativeCredit?: string;
  salesNotes?: string;
  license?: string;
  linkToLicense?: string;
  charitableGivingDetails?: string;
  website?: string;
  scriptTypeAndVersion?: string;
  mintStartsAt?: Date;
  projectId?: string;
}

const FALLBACK_COLLECTION_AVATAR_URL = `${getBasePath()}/logos/default-avatar-grey.jpg`;

const CollectionHeader = ({
  id,
  slug,
  name,
  image,
  contractAddress,
  description,
  floorPrice,
  volume,
  supply,
  owners,
  loading,
  enableAlgoExplore = false,
  artistIds,
  platformData,
  artistDisplayNotes,
  creativeCredit,
  salesNotes,
  license,
  linkToLicense,
  charitableGivingDetails,
  website,
  scriptTypeAndVersion,
  mintStartsAt,
  projectId,
}: CollectionHeader) => {
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const [displayNotesOpen, setDisplayNotesOpen] = useState<boolean>(false);
  const [showDisplayButton, setShowDisplayButton] = useState<boolean>(false);
  const [listedTotal, setListedTotal] = useState<number | null>(null);

  useEffect(() => {
    getListedTotal();
  }, [id]);

  const getListedTotal = async () => {
    if (!id) return;

    const params = {
      filters: { onlyBuyNow: true, collectionId: id },
      limit: 200,
    };
    const response = await listAssets(params);

    setListedTotal(response?.total);
  };

  const displayNotesRef = useRef<HTMLParagraphElement>(null);

  // If the project description is open, show "Read more" buttons only
  // if the SalesNotes and DisplayNotes text are overflowing
  useEffect(() => {
    const checkOverflow = (element: HTMLParagraphElement | null) =>
      (element && element.scrollHeight > element.clientHeight) || false;

    setShowDisplayButton(checkOverflow(displayNotesRef.current));
  }, [descriptionOpen]);

  const formattedReleaseDate = useFormattedDate(
    mintStartsAt,
    useMemo(() => {
      return new Intl.DateTimeFormat(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    }, [])
  );

  return (
    <>
      <div className="justify-between bg-white dark:text-white dark:bg-neutral-900 items-bottom">
        <div className="p-8 pb-4">
          {loading ? null : (
            <>
              <div className="flex justify-between w-full">
                <Avatar
                  imageUrl={image || FALLBACK_COLLECTION_AVATAR_URL}
                  size="96"
                />

                <div className="hidden gap-3 sm:flex">
                  {enableAlgoExplore ? (
                    <Button
                      as="Link"
                      variant="secondary"
                      href={`/collections/${slug || id}/explore`}
                    >
                      <Sparkles />
                      <span>Explore Algorithm</span>
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <h1 className="font-medium tracking-tight text-header-m">
                  {name}
                </h1>
                <div className="flex flex-wrap items-center gap-3 py-2 my-1">
                  {artistIds?.map((id: string, idx: number) => {
                    return (
                      <div key={idx}>
                        {idx > 0 ? (
                          <span className="block w-1 h-1 rounded-full bg-neutral-300"></span>
                        ) : null}
                        <ArtistItem id={id} />
                      </div>
                    );
                  })}

                  {platformData ? (
                    <>
                      <span className="block w-1 h-1 rounded-full bg-neutral-300"></span>
                      <Link
                        href={`/category/${platformData.slug}`}
                        className="transition hover:opacity-60"
                      >
                        <div className="flex items-center gap-2">
                          <img
                            className="w-5 h-5 rounded-md"
                            src={platformData?.logoImageUrl}
                          />
                          <p className="font-medium truncate text-p-m">
                            {platformData?.name}
                          </p>
                        </div>
                      </Link>
                    </>
                  ) : null}
                  {contractAddress ? (
                    <>
                      <span className="block w-1 h-1 rounded-full bg-neutral-300"></span>
                      <a
                        href={getEtherscanWalletUrl(contractAddress)}
                        target="blank"
                        rel="noreferrer"
                        className="transition hover:opacity-50"
                      >
                        <img
                          className="flex-shrink-0 w-4 h-4"
                          src={`${getBasePath()}/logos/etherscan-logo-circle.svg`}
                        />
                      </a>
                    </>
                  ) : null}
                </div>
                <div className="max-w-md mt-2">
                  <Markdown
                    className={`${
                      descriptionOpen ? null : "line-clamp-2"
                    } text-p-s whitespace-pre-line`}
                  >
                    {description}
                  </Markdown>
                  <div className={descriptionOpen ? "space-y-2" : "hidden"}>
                    <div className="py-2 mb-3 border-b-2 dark:border-neutral-700" />
                    <AboutDetailItem
                      title={"Release Date"}
                      body={formattedReleaseDate}
                    />
                    <AboutDetailItem
                      title="Library"
                      body={scriptTypeAndVersion}
                    />
                    {artistDisplayNotes && (
                      <div className="space-y-0">
                        <p className="mt-1 text-neutral-500 text-[11px] tracking-[1px] dark:text-neutral-300 font-medium uppercase">
                          Display Notes
                        </p>
                        <div
                          ref={displayNotesRef}
                          className={`${displayNotesOpen ? null : "line-clamp-2 text-ellipsis"} text-p-s`}
                        >
                          <Markdown>{`${artistDisplayNotes}`}</Markdown>
                        </div>
                        {showDisplayButton && (
                          <button
                            className="font-medium text-p-s"
                            onClick={() =>
                              setDisplayNotesOpen(!displayNotesOpen)
                            }
                          >
                            {displayNotesOpen ? "Read less" : "Read more"}
                          </button>
                        )}
                      </div>
                    )}
                    <AboutDetailItem
                      title={"Creative Credits"}
                      body={creativeCredit}
                    />
                    <AboutDetailItem title={"Sales Notes"} body={salesNotes} />
                    <AboutDetailItem
                      title={"Charitable Giving"}
                      body={charitableGivingDetails}
                    />
                    <AboutDetailItem title={"Artist Website"} body={website} />
                    <AboutDetailItem title={"Project ID"} body={projectId} />
                    <AboutDetailItem
                      title={"License"}
                      body={license}
                      link={linkToLicense}
                    />
                  </div>
                  <button
                    className="font-medium text-p-s"
                    onClick={() => setDescriptionOpen((prev: boolean) => !prev)}
                  >
                    {descriptionOpen ? "Read less" : "Read more"}
                  </button>
                </div>
              </div>
              {enableAlgoExplore ? (
                <div className="mt-4 sm:hidden">
                  <Button
                    as="Link"
                    variant="secondary"
                    href={`/collections/${id}/explore`}
                  >
                    <Sparkles />
                    <span>Explore Algorithm</span>
                  </Button>
                </div>
              ) : null}

              <div className="flex flex-wrap items-end gap-12 mt-8">
                <HeaderStatItem
                  title={"Items"}
                  valueSection={
                    <p className="font-medium text-p-xl">
                      {numberWithCommas(supply as number)}
                    </p>
                  }
                />
                <HeaderStatItem
                  title={"Owners"}
                  valueSection={
                    <p className="font-medium text-p-xl">
                      {numberWithCommas(owners as number)}
                    </p>
                  }
                />

                <HeaderStatItem
                  title="Total Volume"
                  valueSection={
                    <EthLabel size={"xl"} amount={volume as number} />
                  }
                />
                <HeaderStatItem
                  title="Floor Price"
                  valueSection={
                    <EthLabel size={"xl"} amount={floorPrice as number} />
                  }
                />
                <HeaderStatItem
                  title="Listed"
                  valueSection={
                    <p className="font-medium text-p-xl">
                      {supply && listedTotal
                        ? `${((listedTotal / supply) * 100).toFixed(2)}%`
                        : "--"}
                    </p>
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const AboutDetailItem = ({
  title,
  body,
  link,
}: {
  title?: string;
  body?: string;
  link?: string;
}) => {
  if (!title || !body) return null;

  return (
    <div className="space-y-0">
      <p className="mt-1 text-[11px] tracking-[1px] text-neutral-500 dark:text-neutral-300 font-medium uppercase">
        {title}
      </p>
      <p className="text-p-s">
        {link ? (
          <Link
            href={link}
            className="transition hover:opacity-60"
            target="_blank"
          >
            {body}
          </Link>
        ) : (
          `${body}`
        )}
      </p>
    </div>
  );
};

export default CollectionHeader;
