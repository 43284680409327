import { useEffect, useState } from "react";

const TimeSinceLabel = ({ lastUpdated }: { lastUpdated: Date }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = () => {
      setCount(count + 1);
    };

    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [count]);

  useEffect(() => {
    setCount(0);
  }, [lastUpdated]);

  const renderTimeLabel = () => {
    if (count > 60 && count < 3600) {
      const minutes = Math.floor(count / 60);
      return <span>{minutes} minutes ago</span>;
    } else if (count > 3600) {
      const hours = Math.floor(Math.floor(count / 60) / 60);
      return <span>{hours} hours ago</span>;
    } else {
      return <span>{count}s ago</span>;
    }
  };

  return <>{renderTimeLabel()}</>;
};

export default TimeSinceLabel;
