interface HeaderStatItem {
  title: string;
  valueSection: any;
}

export const HeaderStatItem = ({ title, valueSection }: HeaderStatItem) => {
  return (
    <div>
      {valueSection}
      <p className="text-p-s text-neutral-600 dark:text-neutral-400">{title}</p>
    </div>
  );
};
