import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Check } from "client/components/icons/check";
import { Chevron as ChevronIcon } from "client/components/icons/chevron";
import { useState } from "react";
import CheckBox from "../CheckBox";
import { getBasePath } from "@/shared/config";

const SQUIGGLE_DAO_TRAIT_KEY = "SquiggleDAO";
const SQUIGGLE_DAO_TRAIT_LOGO_PATH = `${getBasePath()}/logos/squiggle-dao.png`;

const TraitSelector = ({
  traitKey,
  traitKind,
  traitValues,
  selectedTraits,
  addTraitFilter,
  removeTraitFilter,
  exploreTrait,
  selectedExploreTrait,
}: {
  traitKey: string;
  traitKind?: "string" | "number" | "boolean";
  traitValues: { value: string | number | boolean; count?: number }[];
  selectedTraits: any;
  addTraitFilter: any;
  removeTraitFilter: any;
  exploreTrait?: any;
  selectedExploreTrait?: any;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const isExploring = selectedExploreTrait === traitKey;
  const selected = traitKey in selectedTraits;

  return (
    <div className="trait-box flex gap-1">
      <div
        className={`mb-4 flex-grow rounded-xl overflow-hidden dark:border-neutral-700 border-2 ${
          selected || isExploring ? "border-black dark:border-white" : null
        }`}
      >
        <button
          className="py-3 px-3 w-full flex justify-between"
          onClick={() => setOpen((prev: boolean) => !prev)}
        >
          <div className="flex items-center gap-1 text-left">
            {selected ? <Check className="flex-shrink-0" /> : null}
            {renderTraitKeyLogo(traitKey)}
            <p>{traitKey}</p>
          </div>

          <div className="flex items-center">
            <ChevronIcon
              className={`${
                open ? "rotate-180" : null
              } transform transition-all duration-300 flex-shrink-0`}
            />
          </div>
        </button>

        <div
          className={`${
            open ? "h-64" : "h-0"
          } transition-all duration-300 overflow-y-auto px-3`}
        >
          {traitValues?.length < 1000 &&
            traitValues?.map((trait: any, idx: number) => {
              const checked =
                selectedTraits?.[traitKey] &&
                trait.value in selectedTraits[traitKey];

              const id = `${traitKey}-${idx}`;
              return (
                <label
                  htmlFor={id}
                  key={idx}
                  className="flex cursor-pointer dark:hover:bg-neutral-800 items-center justify-between mb-1 px-2 py-2 hover:bg-neutral-100 rounded-md"
                >
                  <p className="text-sm dark:text-white text-gray-900">
                    {`${trait.value}`}
                  </p>
                  <div className="flex items-center">
                    {trait.count ? (
                      <p className="mr-2 text-sm">{trait.count}</p>
                    ) : null}
                    <CheckBox
                      id={id}
                      isChecked={checked}
                      onChange={(e: any) => {
                        e.target.checked
                          ? addTraitFilter(traitKey, trait.value)
                          : removeTraitFilter(traitKey, trait.value);
                      }}
                    />
                  </div>
                </label>
              );
            })}
        </div>
        {exploreTrait ? (
          <button
            onClick={() => exploreTrait?.(traitKey)}
            className={`${
              isExploring
                ? "bg-black dark:bg-white dark:text-black dark:hover:bg-neutral-100 text-white hover:bg-neutral-800 transition"
                : "hover:bg-neutral-100 dark:hover:bg-neutral-800 transition"
            } w-full justify-between text-sm flex items-center gap-2 border-t dark:border-neutral-700 px-3 py-2`}
          >
            {!isExploring ? (
              <>
                <p>Explore</p>
                <EyeIcon className="text-neutral-400 h-5 w-5" />
              </>
            ) : (
              <>
                <p>Exploring</p>
                <EyeSlashIcon className="text-white dark:text-neutral-900 h-5 w-5" />
              </>
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

const renderTraitKeyLogo = (traitKey: string) => {
  if (traitKey === SQUIGGLE_DAO_TRAIT_KEY)
    return (
      <img
        className="h-5 w-5 rounded-sm mr-1"
        src={SQUIGGLE_DAO_TRAIT_LOGO_PATH}
      />
    );
  return null;
};

export default TraitSelector;
