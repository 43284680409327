import CheckBox from "./CheckBox";

const SelectBox = ({
  title,
  onChange,
  isSelected,
  icon,
  bordered = false,
}: {
  title: string;
  onChange: any;
  isSelected: boolean;
  icon?: any;
  bordered?: boolean;
}) => {
  return (
    <div
      className={`rounded-xl hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer p-2 ${
        bordered ? "border-2" : null
      } ${
        isSelected
          ? "border-neutral-900 dark:border-neutral-500"
          : "border-neutral-200 dark:border-neutral-800"
      }`}
    >
      <label htmlFor={title}>
        <div className="flex items-center justify-between cursor-pointer">
          <div className="flex items-center gap-2">
            {icon ? icon : null}
            <p>{title}</p>
          </div>
          <CheckBox id={title} isChecked={isSelected} onChange={onChange} />
        </div>
      </label>
    </div>
  );
};

export default SelectBox;
