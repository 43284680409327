import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Edit = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M3.16666 12.8333L5.99999 12.1666L12.1953 5.97136C12.4556 5.71101 12.4556 5.28891 12.1953 5.02856L10.9714 3.8047C10.711 3.54435 10.2889 3.54435 10.0286 3.8047L3.83332 9.99996L3.16666 12.8333Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8333 12.8333H9.16666"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
