import { useMemo } from "react";

import { getBasePath } from "@/shared/config";
import Button from "@/client/components/frame-design-system/buttons/Button";
import { External } from "@/client/components/icons/external";
import { Clock } from "@/client/components/icons/clock";
import { useFormattedDate } from "@/client/components/hooks/useLocalizedDate";
import Image from "next/image";

export function ExhibitionBanner({
  name,
  description,
  link,
  exhibitionTypeBanner,
  imageUrl,
  mintStartsAt,
}: {
  name: string;
  description: string;
  link: string;
  exhibitionTypeBanner: string;
  imageUrl: string;
  mintStartsAt?: Date;
}) {
  // only show release date for future releases
  const showReleaseDate = mintStartsAt && mintStartsAt?.getTime() > Date.now();
  const formattedReleaseDate = useFormattedDate(
    mintStartsAt,
    useMemo(() => {
      return new Intl.DateTimeFormat(undefined, {
        weekday: "long",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      });
    }, [])
  );

  return (
    <div className="flex flex-col p-8 mt-4 md:flex-row md:h-[600px] h-screen">
      <div className="flex flex-col text-center justify-between bg-neutral-100 dark:bg-neutral-800 w-full md:w-1/2 p-8 dark:text-white rounded-t-lg md:rounded-bl-lg md:rounded-tl-lg md:rounded-t-none">
        <div className="flex flex-col gap-4 sm:flex-row items-center justify-between">
          <div className="hidden dark:block">
            <img
              className="h-6 text-neutral-900"
              src={`${getBasePath()}/platforms/artblocks-curated-lockup.svg`}
            />
          </div>
          <div className="dark:hidden">
            <img
              className="h-6 text-neutral-900"
              src={`${getBasePath()}/platforms/artblocks-curated-lockup-black.svg`}
            />
          </div>
          {showReleaseDate ? (
            <div className="bg-neutral-200 rounded-full pl-2 pr-3 py-1 text-p-xs text-neutral-500 flex items-center gap-1 dark:bg-neutral-700 dark:text-neutral-300">
              <Clock className="h-4 w-4" />
              {formattedReleaseDate}
            </div>
          ) : null}
        </div>

        <div className="text-center flex flex-col items-center mt-4 md:items-start md:text-left md:mt-none">
          <p className="text-header-l mb-1 font-medium">{name}</p>
          <p className="uppercase font-medium text-p-xs text-neutral-400 dark:text-neutral-200 mb-3">
            {exhibitionTypeBanner}
          </p>
          <p className="text-p-base dark:text-neutral-400 text-neutral-600 max-w-sm">
            {description}
          </p>
          <div className="mt-10">
            <Button size="large" as="a" href={link} target="_blank">
              Discover the Exhibition <External />
            </Button>
          </div>
        </div>
      </div>
      <div className="flex w-full md:w-1/2 h-full">
        <img
          className="h-full w-full object-cover rounded-b-lg md:rounded-b-none md:rounded-br-lg md:rounded-tr-lg"
          src={imageUrl}
        />
      </div>
    </div>
  );
}
