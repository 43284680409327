import { useEffect, useMemo, useState } from "react";

import { Collection } from "@/shared/types/collection";

import { shallowEqual } from "@xstate/react";
import { CollectionOverviewTokenDisplay } from "./CollectionOverviewTokenDisplay";
import { PurchaseModal } from "./PurchaseModal";

import SimpleButton, {
  SimpleButtonProps,
} from "@/client/components/common/Button";
import { cn } from "@/client/lib/classnames";
import { getBasePath } from "@/shared/config";
import { useRouter } from "next/router";
import { ProjectSaleManagerMachineContext } from "../../ProjectSaleManagerMachineContext";
import { PurchaseDetails } from "./PurchaseDetails";
import { ErrorIcon } from "@/client/components/icons/error-icon";
import { formatGoogleCalendarLinkForCollection } from "@/client/lib/helpers";
import { CalendarPlus } from "@/client/components/icons/calendar-plus";
import { isSettlementMinterType } from "./helpers";

export const CollectionOverview = ({
  collection,
}: {
  collection: Collection;
}) => {
  const router = useRouter();

  const artblocksProject = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.project;
    }
  );

  const liveSaleData = ProjectSaleManagerMachineContext.useSelector((state) => {
    return state.context.liveSaleData;
  }, shallowEqual);

  const projectSaleState = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state;
    },
    (a, b) => {
      return a.value === b.value;
    }
  );

  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

  const { paused } = liveSaleData ?? { paused: true };

  const startDateString =
    artblocksProject?.auction_start_time ?? artblocksProject?.start_datetime;
  const startDate = useMemo(() => {
    return startDateString ? new Date(startDateString) : null;
  }, [startDateString]);

  const isArtBlocksFlagship =
    artblocksProject?.contract.default_vertical?.category.hosted;

  const projectSaleHasStarted = !startDate || startDate <= new Date();
  const projectIsPaused = paused;

  // The modal should be allowed to open if either:
  // 1. The project sale has started and is not paused, meaning the project
  //    would be purchasable if the user connects their wallet. This allows
  //    users to open the modal and go through the wallet connection process,
  //    even if they haven't connected their wallet yet.
  // 2. The project sale state is "readyForSale", indicating that the sale is
  //    purchasable and the user has already connected their wallet.
  const allowModalOpen =
    (projectSaleHasStarted && !projectIsPaused) ||
    projectSaleState.matches("readyForPurchase");

  // If the modal is not allowed to open, force close it
  useEffect(() => {
    if (!allowModalOpen) {
      setPurchaseModalOpen(false);
    }
  }, [allowModalOpen]);

  // if project is using the settlement minter, show the settlement note
  const minterType =
    artblocksProject?.minter_configuration?.minter?.minter_type;
  const showSettlementNote = isSettlementMinterType(minterType);

  // build the google calendar link
  let addToGcalLink: string;
  if (startDate) {
    addToGcalLink = formatGoogleCalendarLinkForCollection({
      collectionName: artblocksProject?.name ?? "",
      artistName: artblocksProject?.artist_name ?? "",
      startsAt: startDate,
      slugOrId: artblocksProject?.id ?? "",
    });
  }

  return (
    <div className="flex flex-col p-8 sm:flex-row">
      {/* Featured token display // Minted token display */}
      <div className="flex items-center flex-1 p-8 mb-8 sm:mb-0 bg-neutral-100 dark:bg-neutral-800">
        <CollectionOverviewTokenDisplay
          fallbackTokenImageUrl={collection.imageUrl}
        />
      </div>
      {/* Project details and purchase flow */}
      <div className="relative flex-1 sm:p-8">
        <div>
          <PurchaseModal
            open={purchaseModalOpen}
            onClose={() => setPurchaseModalOpen(false)}
          />
          <div className="mb-5">
            <PurchaseDetails platformData={collection.platformData} />
          </div>
          {(() => {
            switch (true) {
              case projectSaleState.matches("projectSaleComplete"): {
                return (
                  <CollectionOverviewButton
                    as="Link"
                    variant="outline"
                    href={{
                      pathname: router.pathname,
                      query: { id: router.query.id, tab: "items" },
                    }}
                    shallow={true}
                    className="shadow-sm"
                  >
                    Purchase via Secondary
                  </CollectionOverviewButton>
                );
              }
              case projectSaleState.matches(
                "projectIneligibleForPrimarySale"
              ): {
                return (
                  <CollectionOverviewButton
                    as="button"
                    variant="outline"
                    disabled={true}
                    title="Project may be minting on external website"
                    className="shadow-sm disabled:cursor-not-allowed"
                  >
                    <img
                      src={`${getBasePath()}/logos/artblocks.png`}
                      className="w-4 h-4 mr-2"
                    />
                    Minting not supported on Art Blocks
                  </CollectionOverviewButton>
                );
              }
              case projectSaleState.matches("idle"): {
                let buttonMessage: string | undefined;
                let buttonOnClick: (() => void) | undefined;
                let buttonIcon = null;
                let outlineButton = false;

                if (allowModalOpen) {
                  // this case indicates available for purchase, but no wallet connected
                  buttonMessage = "Connect Wallet";
                  buttonOnClick = () => {
                    setPurchaseModalOpen(true);
                  };
                } else if (
                  startDate &&
                  startDate > addMinutes(new Date(), 30)
                ) {
                  // > 30 minutes before start, show add to calendar
                  buttonMessage = "Add to Calendar";
                  buttonOnClick = () => {
                    window.open(addToGcalLink, "_blank");
                  };
                  buttonIcon = <CalendarPlus className="mr-1" size={20} />;
                  outlineButton = true;
                } else if (startDate && startDate > new Date()) {
                  buttonMessage = "Waiting For Start";
                } else if (paused) {
                  buttonMessage = "Purchasing is currently paused";
                }

                if (!buttonMessage) return null;

                return (
                  <CollectionOverviewButton
                    onClick={buttonOnClick}
                    disabled={!buttonOnClick}
                    variant={outlineButton ? "outline" : "primary"}
                  >
                    {buttonIcon}
                    {buttonMessage}
                  </CollectionOverviewButton>
                );
              }
              case projectSaleState.matches("readyForPurchase"): {
                if (!projectSaleState.context.purchaseInitiationMachine) {
                  return null;
                }

                return (
                  <CollectionOverviewButton
                    onClick={() => {
                      setPurchaseModalOpen(true);
                    }}
                  >
                    Purchase
                  </CollectionOverviewButton>
                );
              }
              case projectSaleState.matches("error"): {
                return (
                  <div className="flex flex-col items-center">
                    <ErrorIcon size={44} className="mb-2" />
                    <CollectionOverviewMessage
                      message="An error occurred"
                      subMessage={projectSaleState.context.errorMessage ?? ""}
                      className="mb-8"
                    />
                  </div>
                );
              }
              default: {
                return null;
              }
            }
          })()}
          {showSettlementNote ? (
            <CollectionOverviewMessage
              message=""
              subMessage="Note: The final bid determines the clearing price"
              className="mt-4 text-p-s"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

function CollectionOverviewMessage({
  message,
  subMessage,
  className,
}: {
  message: string;
  subMessage: string;
  className?: string;
}) {
  return (
    <div
      className={cn(`text-center flex-1 text-black dark:text-white`, className)}
    >
      <p className="text-p-m">{message}</p>
      <p className="opacity-60">{subMessage}</p>
    </div>
  );
}

function CollectionOverviewButton({ className, ...props }: SimpleButtonProps) {
  return (
    <SimpleButton
      className={cn(`w-full p-4 text-center justify-center`, className)}
      {...props}
    />
  );
}

// helper function to add minutes to a date
function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

export default CollectionOverview;
