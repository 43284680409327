import { useState, useEffect } from "react";
import { listCollections, readArtist } from "client/lib/api";
import { XLogo } from "client/components/icons/x-logo";
import Link from "next/link";
import {
  findLowestEntry,
  findTotalVolume,
  findTotalPieces,
} from "client/lib/statsHelpers";
interface ArtistTab {
  collection?: Collection;
}

const ArtistTab = ({ collection }: ArtistTab) => {
  const artistId = collection?.artistIds?.[0];
  const artistSlug = collection?.artistSlugs?.[0];

  const [artist, setArtist] = useState<any>(null);
  const [collections, setCollections] = useState<any>(null);
  const [lowestEntry, setLowestEntry] = useState<number | null>(null);
  const [totalVolume, setTotalVolume] = useState<number | null>(null);
  const [totalPieces, setTotalPieces] = useState<number | null>(null);

  useEffect(() => {
    if (artistId) {
      fetchArtist();
      fetchCollections();
    }
  }, [collection]);

  const fetchArtist = async () => {
    if (!artistId) return;
    let res = await readArtist(artistId);
    setArtist(res);
  };

  const fetchCollections = async () => {
    if (!artistId) return;
    let res = await listCollections({ artistId });
    setCollections(res);
  };

  useEffect(() => {
    if (collections?.length > 0) {
      setLowestEntry(findLowestEntry(collections));
      setTotalVolume(findTotalVolume(collections));
      setTotalPieces(findTotalPieces(collections));
    }
  }, [collections]);

  const getAvatarImage = () => {
    if (artist && artist.avatarUrl) return artist.avatarUrl;

    if (collections && collections[collections.length - 1]?.imageUrl)
      return collections[collections.length - 1]?.imageUrl;

    return "";
  };

  return (
    <div className="px-6 mt-8">
      <div className="mt-1 w-16 h-16 relative flex-shrink-0 rounded-full overflow-hidden">
        <div className="absolute anim-loading bg-loading-light dark:bg-loading-dark top-0 left-0 w-full h-full"></div>
        <div
          className="absolute bg-cover bg-center top-0 left-0 w-full h-full block"
          style={{
            backgroundImage: `url(${getAvatarImage()})`,
          }}
        ></div>
      </div>

      {artist ? (
        <div className="flex items-center gap-3 mt-4 ">
          <Link
            href={`/artists/${artistSlug || artistId}`}
            className="text-xl transition hover:text-neutral-500 block"
          >
            {artist?.name}
          </Link>
          {artist?.twitterUsername ? (
            <a
              href={`https://x.com/${artist?.twitterUsername}`}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 transition cursor-pointer"
            >
              <XLogo className="h-4 w-4 flex-shrink-0 text-neutral-500 dark:text-neutral-400" />
            </a>
          ) : null}
        </div>
      ) : (
        <div className="h-6 w-32 rounded-md mt-5 anim-loading bg-loading-light dark:bg-loading-dark"></div>
      )}

      <p
        style={{ fontSize: 11, letterSpacing: 1 }}
        className="mt-8 text-neutral-500 dark:text-neutral-300 font-medium uppercase"
      >
        Artist Stats
      </p>
      {artist && collections ? (
        <div className="flex flex-col border p-3 rounded-md text-sm gap-3 mt-4 dark:text-neutral-300 dark:bg-neutral-800 dark:border-neutral-800 text-neutral-600">
          {collections ? (
            <div className="flex items-center justify-between">
              <p>Collections</p>
              <span className="text-black dark:text-white">
                {collections.length}
              </span>
            </div>
          ) : null}
          {totalPieces ? (
            <div className="flex items-center justify-between">
              <p>Pieces in Circulation</p>
              <span className="text-black dark:text-white flex items-center">
                {totalPieces > 1000 ? (
                  <>
                    {totalPieces % 1000 === 0 ? (
                      <>{(totalPieces / 1000).toFixed(0) + "K"}</>
                    ) : (
                      <>{(totalPieces / 1000).toFixed(1) + "K"}</>
                    )}
                  </>
                ) : (
                  <>{totalPieces.toFixed(0)}</>
                )}
              </span>
            </div>
          ) : null}

          {totalVolume ? (
            <div className="flex justify-between items-center">
              <p>Total Volume</p>
              <span className="text-black dark:text-white flex items-center">
                <Eth
                  className="text-neutral-500 dark:text-neutral-300"
                  size={14}
                />
                {totalVolume > 1000 ? (
                  <>
                    {totalVolume % 1000 === 0 ? (
                      <>{(totalVolume / 1000).toFixed(0) + "K"}</>
                    ) : (
                      <>{(totalVolume / 1000).toFixed(1) + "K"}</>
                    )}
                  </>
                ) : (
                  <>{totalVolume.toFixed(0)}</>
                )}
              </span>
            </div>
          ) : null}

          {lowestEntry ? (
            <div className="flex justify-between items-center">
              <p>Lowest Entry</p>
              <span className="text-black dark:text-white flex items-center">
                <Eth
                  className="text-neutral-500 dark:text-neutral-300"
                  size={14}
                />
                {lowestEntry}
              </span>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="h-32 w-full rounded-md mt-4 anim-loading bg-loading-light dark:bg-loading-dark"></div>
      )}

      <p
        style={{ fontSize: 11, letterSpacing: 1 }}
        className="mt-8 text-neutral-500 dark:text-neutral-300 font-medium uppercase"
      >
        All Collections
      </p>
      {!collections ? (
        <div className="h-32 w-full rounded-md mt-4 anim-loading bg-loading-light dark:bg-loading-dark"></div>
      ) : (
        <div className="flex flex-col gap-3 my-4">
          {collections?.map((collection: any) => {
            return (
              <CollectionCard key={collection?._id} collection={collection} />
            );
          })}
        </div>
      )}
    </div>
  );
};

import { Eth } from "../icons/eth";
import CategoryBadge from "./CategoryBadge";
import { Collection } from "shared/types/collection";

const CollectionCard = ({ collection }: { collection: any }) => {
  return (
    <Link href={`/collections/${collection?.slug || collection?.id}`}>
      <div className="rounded-md dark:border-neutral-700 dark:hover:bg-neutral-800 border overflow-hidden cursor-pointer hover:bg-neutral-100 transition">
        <div className="flex items-start gap-3 p-3">
          <div className="mt-1 w-12 h-12 relative flex-shrink-0 rounded-md overflow-hidden">
            <div className="absolute anim-loading bg-loading-light dark:bg-loading-dark top-0 left-0 w-full h-full"></div>
            <div
              className="absolute bg-cover bg-center top-0 left-0 w-full h-full block"
              style={{
                backgroundImage: collection?.imageUrl
                  ? `url(${collection?.imageUrl})`
                  : "",
              }}
            ></div>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <p className="line-clamp-1 text-sm">{collection?.name}</p>
              <CategoryBadge
                status={collection?.artBlocksCurationStatus}
                heritageStatus={collection?.artBlocksHeritageCurationStatus}
                size={4}
              />
            </div>
            <p className="text-neutral-600 dark:text-neutral-400 mt-1 line-clamp-2 text-xs">
              {collection?.description}
            </p>
            <p className="flex text-xs my-1 dark:text-neutral-400 text-neutral-600 items-center">
              {collection?.stats?.supplyTotal
                ? collection?.stats?.supplyTotal.toFixed(0)
                : "--"}{" "}
              Pieces
            </p>
          </div>
        </div>
        <div className="p-2 pl-1 bg-neutral-100 text-xs dark:bg-neutral-800  justify-between flex items-center">
          <p className="flex items-center">
            <Eth
              size={14}
              className="text-neutral-500 dark:text-neutral-300 ml-1"
            />
            {collection?.stats?.floorPrice
              ? collection?.stats?.floorPrice
              : "--"}
          </p>
          <p className="flex items-center">
            Volume:{" "}
            <Eth
              size={14}
              className="text-neutral-500 dark:text-neutral-300 ml-1"
            />
            {collection?.stats?.volumeTotal
              ? collection?.stats?.volumeTotal.toFixed(0)
              : "--"}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ArtistTab;
