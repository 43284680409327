import EthLabel from "@/client/components/frame-design-system/labels/EthLabel";
import { Eth } from "client/components/icons/eth";
import { Asset } from "shared/types/asset";

const TraitExploreItem = ({
  traitType,
  value,
  floorAskPrice,
  assetCount,
  sampleAssets,
  addTraitFilter,
}: {
  traitType: string;
  value: string;
  floorAskPrice?: number;
  assetCount?: number;
  sampleAssets?: Asset[];
  addTraitFilter: any;
}) => {
  return (
    <div className="border dark:border-neutral-700 dark:text-white hover:bg-neutral-100 dark:hover:bg-neutral-800 transition rounded-md overflow-hidden flex flex-col">
      <button
        className="w-full h-full flex flex-col"
        onClick={() => addTraitFilter(traitType, value)}
      >
        <div className="p-2 px-3 w-full">
          <div className="flex justify-between items-center">
            <p className="text-left line-clamp-1">{`${value}`}</p>
            {!floorAskPrice ? (
              <p className="text-xs text-neutral-500 dark:text-neutral-400">
                None listed
              </p>
            ) : (
              <EthLabel amount={floorAskPrice} />
            )}
          </div>
          <p className="text-sm text-left text-neutral-500 dark:text-neutral-400">
            {assetCount} Pieces
          </p>
        </div>
        <div className="grid grid-cols-2 bg-[#ebebeb] flex-grow">
          {sampleAssets?.map((token: any) => {
            return (
              <img
                src={token?.imageThumbnailUrl}
                className="w-full anim-loading bg-loading-light dark:bg-loading-dark"
              />
            );
          })}
        </div>
      </button>
    </div>
  );
};

export default TraitExploreItem;
