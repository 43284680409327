import Countdown from "react-countdown";
import { Arrow } from "@/client/components/icons/arrow";
import { Ethereum } from "@/client/components/icons/ethereum";
import { cn } from "@/client/lib/classnames";
import { PlatformData } from "@/shared/platforms";
import { shallowEqual } from "@xstate/react";
import { formatEther } from "viem";
import {
  ProjectSaleManagerMachineContext,
  usePurchaseInitiationMachine,
} from "../../ProjectSaleManagerMachineContext";
import {
  getConciseMinterLabel,
  isAllowlistMinterType,
  isAuctionMinterType,
  useFormattedProjectDate,
  usePriceDisplay,
} from "./helpers";

interface PurchaseDetails {
  platformData?: PlatformData;
}

export function PurchaseDetails({ platformData }: PurchaseDetails) {
  const artblocksProject = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state.context.project;
    }
  );

  // The liveSaleData object is refreshed with each update
  // from the machine. To optimize performance and avoid redundant
  // re-renders, we employ shallowEqual for state comparison.
  const liveSaleData = ProjectSaleManagerMachineContext.useSelector((state) => {
    return state.context.liveSaleData;
  }, shallowEqual);

  const { state: purchaseInitiationMachineState } =
    usePurchaseInitiationMachine();

  // Use live invocations and max invocations if available
  // Otherwise, use the project's invocations and max invocations
  // from the database
  const { invocations, maxInvocations } = liveSaleData ?? {};
  let invocationsDisplay = artblocksProject?.invocations ?? "--";
  let maxInvocationsDisplay = artblocksProject?.max_invocations ?? "--";
  if (invocations != null) {
    invocationsDisplay = Number(invocations);
  }
  if (maxInvocations != null) {
    maxInvocationsDisplay = Number(maxInvocations);
  }

  // Convert invocationsDisplay and maxInvocationsDisplay to numbers if they are not "--"
  const numericInvocations =
    invocationsDisplay !== "--" ? Number(invocationsDisplay) : null;
  const numericMaxInvocations =
    maxInvocationsDisplay !== "--" ? Number(maxInvocationsDisplay) : null;

  // Calculate remaining works only if both values are numbers
  let remainingWorks = "--";
  if (
    numericInvocations !== null &&
    numericMaxInvocations !== null &&
    numericMaxInvocations !== 0
  ) {
    remainingWorks = (numericMaxInvocations - numericInvocations).toString();
  }

  // try and get the start date from the project, foundStartDate will be false if there is no start date found
  const startDateString =
    artblocksProject?.auction_start_time ?? artblocksProject?.start_datetime;
  const startDate = startDateString ? new Date(startDateString) : null;
  // Use hook here to avoid hydration issue due to timezone difference
  const formattedStartDate = useFormattedProjectDate(startDate);
  const isFutureDrop = startDate && startDate > new Date();
  const isPurchasable = !(
    isFutureDrop ||
    liveSaleData?.paused ||
    artblocksProject?.paused ||
    remainingWorks === "0"
  );

  // get base price
  const basePriceInWei = artblocksProject?.minter_configuration?.base_price;
  // @dev okay to assume base price is in ETH - currently no ERC20 dynamic pricing
  const basePriceInEth = basePriceInWei
    ? Number(formatEther(BigInt(basePriceInWei)))
        .toFixed(4)
        .toString()
    : "--";

  const minterType =
    artblocksProject?.minter_configuration?.minter?.minter_type;
  const isAuctionMinter = isAuctionMinterType(minterType);
  const isAllowlistMinter = isAllowlistMinterType(minterType);
  const conciseMinterLabel = getConciseMinterLabel(minterType);

  const { priceDisplay, currencySymbol } = usePriceDisplay(
    liveSaleData?.tokenPriceInWei,
    artblocksProject?.minter_configuration?.currency_address,
    artblocksProject?.minter_configuration?.currency_symbol
  );

  const isFutureAuction = isFutureDrop && isAuctionMinter;
  const isActiveAuction =
    !isFutureDrop &&
    isAuctionMinter &&
    (liveSaleData?.tokenPriceInWei ?? BigInt(0)) >
      BigInt(basePriceInWei || "0");

  // assign vertical
  const vertical = platformData?.name;

  return (
    <div>
      <div className="mb-11">
        <p className="tracking-wider uppercase mb-8 text-cap-m opacity-60 dark:text-white">
          {isPurchasable
            ? "HAPPENING NOW"
            : `${isFutureDrop && formattedStartDate ? "STARTS ON " : ""}${formattedStartDate ?? ""}`}
        </p>
        {vertical ? (
          <p className="mb-2 tracking-wider uppercase text-cap-m opacity-60 dark:text-white">
            {vertical}
          </p>
        ) : null}
        <h1 className="font-medium text-header-m dark:text-white">
          {artblocksProject?.name}
        </h1>
        <p className="font-medium text-p-lg dark:text-white">
          {artblocksProject?.artist_name}
        </p>
        <p className="text-p-s opacity-60 dark:text-white">
          {maxInvocationsDisplay} unique artworks
        </p>
      </div>
      <div className="mb-2">
        <p className="text-p-s opacity-60 dark:text-white">
          {conciseMinterLabel}
        </p>
        {/* Countdown to auction start or auction end */}
        {(() => {
          if (isFutureDrop && startDate) {
            return (
              <ProjectAuctionTimer
                prefix="starts in"
                endTime={startDate}
                className="mt-1"
              />
            );
          }

          if (
            startDate &&
            remainingWorks !== "0" &&
            artblocksProject?.auction_end_time &&
            new Date(artblocksProject?.auction_end_time) > new Date()
          ) {
            return (
              <ProjectAuctionTimer
                prefix="ends in"
                endTime={artblocksProject.auction_end_time}
                className="mt-1"
              />
            );
          }

          return null;
        })()}
      </div>
      {/* Allowlist eligibility display */}
      {(() => {
        if (!isAllowlistMinter || !purchaseInitiationMachineState) {
          return null;
        }

        // TODO: Once number of mints is exposed from SDK, show the number of mints the user may purchase
        if (purchaseInitiationMachineState.context.userIneligibilityReason) {
          return (
            <div className="p-2 border border-black border-opacity-10 dark:text-white dark:border-neutral-500">
              <p className="text-p-xs">
                {purchaseInitiationMachineState.context.userIneligibilityReason}
              </p>
            </div>
          );
        }
        if (purchaseInitiationMachineState.context.errorMessage) {
          return (
            <div className="p-2 border border-black border-opacity-10 dark:text-white dark:border-neutral-500">
              <p className="text-p-xs">
                {purchaseInitiationMachineState.context.errorMessage}
              </p>
            </div>
          );
        }

        return (
          <div className="p-2 border border-black border-opacity-10 dark:text-white dark:border-white">
            <p className="text-p-xs">
              You are eligible to purchase from this release
            </p>
          </div>
        );
      })()}
      <div className="flex items-stretch gap-3 mt-11">
        <LiveSaleDataContainer className="flex items-center justify-center flex-1">
          <div>
            <p className="font-medium text-center text-header-m">
              {remainingWorks}
            </p>
            <p className="text-center text-p-m opacity-60">Works remaining</p>
          </div>
        </LiveSaleDataContainer>
        <LiveSaleDataContainer className="flex-1">
          <p className="flex items-center justify-center font-medium text-center text-header-m">
            {isActiveAuction ? (
              <Arrow className="-rotate-90" size={36} />
            ) : null}
            {currencySymbol === "ETH" ? <Ethereum size={42} /> : null}
            <span>{priceDisplay}</span>
            {currencySymbol && currencySymbol !== "ETH" ? (
              <span>&nbsp;{currencySymbol}</span>
            ) : null}
          </p>
          <p className="text-center text-p-m opacity-60">
            {isFutureAuction ? "Starting " : ""}
            {isActiveAuction ? "Current " : ""}
            Price
          </p>
          {isActiveAuction || isFutureAuction ? (
            <div className="flex justify-center mt-0 ">
              <div className="flex items-center text-p-s opacity-60">
                Resting Price:
                <Ethereum size={16} />
                {basePriceInEth}
              </div>
            </div>
          ) : null}
        </LiveSaleDataContainer>
      </div>
    </div>
  );
}

function LiveSaleDataContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "bg-neutral-100 dark:bg-neutral-800 dark:text-white p-3 rounded-lg",
        className
      )}
    >
      {children}
    </div>
  );
}

const ProjectAuctionTimer = ({
  endTime,
  prefix,
  className,
}: {
  endTime: Date | string;
  prefix?: string;
  className?: string;
}): React.ReactElement | null => {
  return (
    <p className={cn("font-medium text-p-s dark:text-white", className)}>
      {prefix || ""}
      <Countdown date={endTime} renderer={countDownRenderer} />
    </p>
  );
};

// This renderer also displays weeks, and auto-adjust the display based on the remaining time
const countDownRenderer = ({ days, hours, minutes, seconds }: any) => {
  const weeks = Math.floor(days / 7);
  days = days % 7;
  let timerValues = [
    {
      label: "wk",
      value: weeks ?? 0,
    },
    {
      label: "d",
      value: days ?? 0,
    },
    {
      label: "hr",
      value: hours ?? 0,
    },
    {
      label: "min",
      value: minutes ?? 0,
    },
    {
      label: "sec",
      value: seconds ?? 0,
    },
  ];
  if (weeks) {
    timerValues = timerValues.slice(0, 3);
  } else if (days) {
    timerValues = timerValues.slice(1, 4);
  } else if (hours) {
    timerValues = timerValues.slice(2, 5);
  } else {
    timerValues = timerValues.slice(3, 5);
  }

  // Render a countdown of appropriate granularity
  // @dev suppress hydration warning for the rendered timer values because they are dynamic
  // see: https://github.com/ndresx/react-countdown?tab=readme-ov-file#why-do-i-get-this-error-warning-text-content-did-not-match
  return (
    <span suppressHydrationWarning>
      {timerValues.map((time) => " " + time.value + " " + time.label)}
    </span>
  );
};
