import { useSelector } from "@xstate/react";

import Button from "@/client/components/frame-design-system/buttons/Button";
import Input from "@/client/components/frame-design-system/inputs/Input";
import { purchaseInitiationMachine } from "@artblocks/sdk/dist/machines/purchase-initiation-machine";
import { useState } from "react";
import { formatUnits, parseUnits } from "viem";
import { ActorRefFrom } from "xstate";
import { PurchaseStepMessage } from "./PurchaseModal";
import { DEFAULT_CRYPTO_DECIMAL_PRECISION } from "@/client/lib/numbers";

export function ERC20AllowanceApprovalForm({
  purchaseInitiationMachineRef,
}: {
  purchaseInitiationMachineRef: ActorRefFrom<typeof purchaseInitiationMachine>;
}) {
  const [error, setError] = useState<string>();

  const project = useSelector(purchaseInitiationMachineRef, (state) => {
    return state.context.project;
  });

  const minAmountToApprove = BigInt(
    project?.minter_configuration?.base_price ?? 0
  );

  const currencySymbol =
    project?.minter_configuration?.currency_symbol ?? "ERC-20";

  const decimals = useSelector(purchaseInitiationMachineRef, (state) => {
    return (
      state.context.additionalPurchaseData?.decimals ??
      DEFAULT_CRYPTO_DECIMAL_PRECISION
    );
  });

  const minAmountToApproveInDisplayUnits = formatUnits(
    minAmountToApprove,
    decimals
  );

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const amount = e.target.value ?? "0";
    const amountInWei = parseUnits(amount, decimals);

    if (amountInWei < minAmountToApprove) {
      setError(
        "Amount must be greater than or equal to minimum purchase amount"
      );
    } else {
      setError(undefined);
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const amountInDisplayUnits = formData.get("amount")?.toString() ?? "0";
    const amount = parseUnits(amountInDisplayUnits, decimals);

    if (amount < minAmountToApprove) {
      return;
    }

    purchaseInitiationMachineRef.send({
      type: "APPROVE_ERC20_ALLOWANCE",
      amount: amount,
    });
  }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <PurchaseStepMessage
        message={`Approve spending of at least ${minAmountToApproveInDisplayUnits} 
        ${currencySymbol}`}
        subMessage="If purchasing multiple works, enter appropriate amount"
      />
      <Input
        type="number"
        step="any"
        onChange={handleChange}
        name="amount"
        defaultValue={minAmountToApproveInDisplayUnits}
        className="mt-4"
      />
      {error ? <p className="mt-2 text-red-600 text-p-xs">{error}</p> : null}
      <div className="flex gap-4 mt-4">
        <Button
          variant="secondary"
          size="large"
          className="flex-1"
          onClick={() => {
            purchaseInitiationMachineRef.send({ type: "RESET" });
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="large"
          className="flex-1 disabled:opacity-60"
          disabled={Boolean(error)}
        >
          Approve
        </Button>
      </div>
    </form>
  );
}
