import { useState, useEffect } from "react";
import Link from "next/link";

import Avatar from "@/client/components/frame-design-system/avatars/Avatar";
import { listCollections, readArtist } from "@/client/lib/api";

interface ArtistItem {
  id: string;
}

export const ArtistItem = ({ id }: ArtistItem) => {
  const [artist, setArtist] = useState<any>(null);
  const [artistCollections, setArtistCollections] = useState<any>(null);

  useEffect(() => {
    getArtist();
    fetchArtistCollections();
  }, []);

  const getArtist = async () => {
    if (!id) return;

    const response = await readArtist(id);

    setArtist(response);
  };

  const fetchArtistCollections = async () => {
    if (!id) return;
    const res = await listCollections({ artistId: id });
    setArtistCollections(res);
  };

  const getAvatarImage = () => {
    if (artist && artist.avatarUrl) return artist.avatarUrl;

    if (
      artistCollections &&
      artistCollections[artistCollections.length - 1]?.imageUrl
    )
      return artistCollections[artistCollections.length - 1]?.imageUrl;

    return "";
  };

  return (
    (<Link
      href={`/artists/${artist?.slug || artist?._id}`}
      className="flex items-center gap-2 transition hover:opacity-50">

      <Avatar imageUrl={getAvatarImage()} rounding="full" />
      <p className="text-p-m font-medium truncate">{artist?.name}</p>

    </Link>)
  );
};
