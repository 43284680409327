import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const ColumnsOne = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 19.7502H9.25C9.80229 19.7502 10.25 19.3025 10.25 18.7502V6.25C10.25 5.69772 9.80229 5.25 9.25 5.25H5.75C5.19772 5.25 4.75 5.69772 4.75 6.25V18.7502C4.75 19.3025 5.19772 19.7502 5.75 19.7502Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 19.7502H18.25C18.8023 19.7502 19.25 19.3025 19.25 18.7502V6.25C19.25 5.69772 18.8023 5.25 18.25 5.25H14.75C14.1977 5.25 13.75 5.69772 13.75 6.25V18.7502C13.75 19.3025 14.1977 19.7502 14.75 19.7502Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
