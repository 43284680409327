interface TabMenu {
  options: TabItem[];
}

const TabMenu = ({ options }: TabMenu) => {
  return (
    <div className="flex items-center gap-4">
      {options?.map((option: TabItem, idx: number) => {
        return (
          <TabItem
            title={option?.title}
            selected={option?.selected}
            action={option?.action}
            key={idx}
          />
        );
      })}
    </div>
  );
};

interface TabItem {
  title: string;
  selected?: boolean;
  action: () => void;
}

const TabItem = ({ title, selected = false, action }: TabItem) => {
  return (
    <button
      onClick={() => action?.()}
      className={`text-p-lg capitalize py-2 px-4 ${
        selected
          ? "bg-neutral-100 dark:text-white dark:bg-neutral-700 text-neutral-900 font-medium rounded-full"
          : "text-neutral-400"
      }`}
    >
      {title}
    </button>
  );
};

export default TabMenu;
