import React, { useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import Confetti from "react-confetti";
import { createPortal } from "react-dom";

export function FullScreenConfetti({ show }: { show: boolean }) {
  const { width, height } = useWindowSize();
  const [renderConfetti, setRenderConfetti] = useState(show);

  useEffect(() => {
    if (!show) {
      const timeoutId = setTimeout(() => {
        setRenderConfetti(false);
      }, 1000); // Assuming the fade out animation lasts 1 second
      return () => clearTimeout(timeoutId);
    }
    setRenderConfetti(true);
  }, [show]);

  return renderConfetti ? (
    <>
      {createPortal(
        <div
          className={`fixed inset-0 pointer-events-none z-40 transition-opacity duration-1000 ${
            show ? "opacity-100" : "opacity-0"
          }`}
        >
          <Confetti width={width} height={height} />
        </div>,
        document.body
      )}
    </>
  ) : null;
}
