import { GetServerSideProps } from "next";

import CollectionPage, { Props } from "@/client/components/pages/Collection";
import { getCollectionCanonicalUrl } from "@/client/lib/links";
import { logger } from "@/server/lib/logger";
import * as collectionService from "@/server/services/collections";
import { listCustomTraitsByCollectionId } from "@/server/services/collections/collectionTraits";
import {
  CHROMIE_SQUIGGLE_ID,
  FRIENDSHIP_BRACELETS_ID,
  METROPOLIS_ID,
  RENDERS_GAME_ID,
} from "@/shared/labelledAddresses";
import { Platform, PlatformFormat } from "@/shared/platforms";
import { Collection } from "@/shared/types/collection";
import { getSerializedSnapshotWithProjectData } from "@artblocks/sdk/dist/machines/project-sale-manager-machine";
import { ArtBlocksClient } from "@artblocks/sdk";
import {
  isProjectDatabaseIdFormat,
  parseProjectDatabaseId,
} from "@/shared/artblocks";
import { isAddress as isWalletAddressFormat } from "@/shared/eth";
import { encode } from "querystring";

const MINTED_OUT_AB_COLLECTIONS = [
  CHROMIE_SQUIGGLE_ID,
  FRIENDSHIP_BRACELETS_ID,
  RENDERS_GAME_ID,
  METROPOLIS_ID,
];

export const getServerSideProps: GetServerSideProps = async (context) => {
  const collectionIdOrSlug = context?.query?.id as string;
  if (
    !collectionIdOrSlug ||
    collectionIdOrSlug === "undefined" ||
    collectionIdOrSlug === "null"
  ) {
    return {
      notFound: true,
    };
  }

  try {
    const collection = await readCollection(collectionIdOrSlug);

    // if we don't have a collection throw an error
    if (!collection) {
      return {
        notFound: true,
      };
    }

    // if the ID used on the page is not the slug of the collection then redirect to the slug
    if (collection.slug && collection.slug !== collectionIdOrSlug) {
      // forward any query params
      const { query } = context;
      // remove collection id from dynamic path segment
      delete query.id;
      return {
        redirect: {
          destination: `/collections/${collection.slug}?${encode(query)}`,
          permanent: false,
        },
      };
    }

    // see if the colection has custom traits
    const customTraits =
      (await listCustomTraitsByCollectionId(collection.id as string))?.traits ||
      [];

    // build up the base set of props
    const props: Props = {
      onlyBuyNow: false,
      onlySansa: false,
      rankless: false,
      sort: { title: "Price: Low to High", sortKey: "FLOOR_PRICE_ASC" },
      collectionId: `${collection._id as string}`,
      collection: JSON.parse(JSON.stringify(collection)),
      metadata: {},
      mintedOut: true,
      aspectRatioIsVariable: false,
      customTraits,
    };

    if (context.query["only-sansa"]) {
      props.onlySansa = true;
    }

    if (context.query["buy-now"]) {
      props.onlyBuyNow = true;
    }

    if (context.query.sort === "FLOOR_PRICE_DESC") {
      props.sort = {
        title: "Price: High to Low",
        sortKey: context.query.sort,
      };
    }

    if (context.query.rankless) {
      props.rankless = true;
    }

    // see if we have preselected traits in the URL
    if (context.query.attributes) {
      // TODO: is there a security concern around decoding and parsing json?
      let attributes = JSON.parse(
        decodeURI(context.query.attributes as string)
      );
      let startingTraits = {} as any;

      attributes.map((trait: any) => {
        let traitType = trait?.name;
        startingTraits[traitType] = {} as any;

        trait?.values?.map((value: string) => {
          startingTraits[traitType][`${value}`] = { value };
        });
      });

      props.preSelectedTraits = startingTraits;
    }

    if (context.query.explore) {
      const exploreTrait = decodeURI(context.query.explore as string);
      props.preSelectedExploredTrait = exploreTrait;
    }

    // build metadata information
    props.metadata = {};
    if (collection?.name)
      props.metadata.title =
        (collection?.name || "") +
        " by " +
        (collection?.artistName || "") +
        " - Collection | Art Blocks";
    if (collection?.description)
      props.metadata.description = buildMetaDescription(collection);
    if (collection?.imageUrl) props.metadata.imageUrl = collection.imageUrl;
    props.metadata.canonicalUrl = getCollectionCanonicalUrl({
      id: collection._id as string,
      slug: collection.slug,
    });

    // Generate a snapshot of the purchase machine to prepopulate the project data
    //
    // Checking for both artBlocksProjectId and platformFormat to be safe because we
    // currently set artBlocksProjectId for BRAIN_DROPS formatted project too even
    // though they are not Art Blocks they follow the same format
    // TODO: don't use artBlocksProjectId for non-Art Blocks projects
    if (
      collection.artBlocksProjectId &&
      collection.platformFormat === PlatformFormat.ArtBlocks
    ) {
      const artblocksClient = new ArtBlocksClient({
        graphqlEndpoint: process.env
          .NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT as string,
      });
      const abHasuraProjectId = `${collection.contractAddress.toLowerCase()}-${
        collection.artBlocksProjectId
      }`;
      props.artBlocksHasuraProjectId = abHasuraProjectId;

      const serializedPurchaseMachineSnapshot =
        await getSerializedSnapshotWithProjectData(
          abHasuraProjectId,
          artblocksClient
        );

      if (serializedPurchaseMachineSnapshot) {
        props.artBlocksPurchaseMachineInitialSnapshot =
          serializedPurchaseMachineSnapshot;
      }
    }

    // if Art Blocks we can check if mint in progress
    if (
      collection.platform === Platform.ArtBlocks &&
      collection?.platformFormat === PlatformFormat.ArtBlocks
    ) {
      props.mintedOut = !!(
        collection?.stats?.supplyTotal &&
        collection?.supplyMax &&
        collection.stats?.supplyTotal >= collection.supplyMax
      );

      // if labeled collections mark as minted out
      if (MINTED_OUT_AB_COLLECTIONS.includes(collection.id as string)) {
        props.mintedOut = true;
      }
    }

    if (collection.aspectRatioIsVariable) {
      props.aspectRatioIsVariable = collection.aspectRatioIsVariable;
    }

    return {
      props,
    };
  } catch (err) {
    logger.error(err, "failed to fetch collection in getServerSideProps");
    throw err;
  }
};

const readCollection = async (collectionIdOrSlug: string) => {
  // if the ID looks like an Art Blocks Database ID check for that first
  if (isProjectDatabaseIdFormat(collectionIdOrSlug)) {
    const { contractAddress, projectId } =
      parseProjectDatabaseId(collectionIdOrSlug);

    return collectionService.findByArtblocksProjectId(
      projectId,
      contractAddress
    );
  }

  // if the ID is a contract address see if we have a single collection for that contract
  if (isWalletAddressFormat(collectionIdOrSlug)) {
    const count =
      await collectionService.countByContractAddress(collectionIdOrSlug);

    // if there is only one then we can map contract -> collection
    if (count === 1)
      return collectionService.findByContractAddress(collectionIdOrSlug);
  }

  // otherwise lookup by ID or slug
  return collectionService.readByIdOrSlug(collectionIdOrSlug);
};

const buildMetaDescription = (collection: Collection) => {
  let baseDescription = `${collection?.description}`;
  baseDescription += `\n\n${collection.name} by ${collection.artistName}`;
  baseDescription += `\n${collection.name} NFTs`;
  baseDescription += `\n${collection.name} floor price`;
  baseDescription += `\n${collection.name} stats`;
  baseDescription += `\n${collection.name} rarity`;
  baseDescription += `\n${collection.name} collectors`;

  return baseDescription;
};

export default CollectionPage;
