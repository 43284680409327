import { isNil, sortBy } from "lodash";
import { useEffect, useState } from "react";

import { exploreCollectionTraits } from "client/lib/api";
import { Close } from "client/components/icons/close";

import TraitExploreItem from "./TraitExploreItem";
import Loading from "client/components/common/Loading";
import Dropdown, { SortOption } from "client/components/common/Dropdown";

type SortTypes =
  | "FLOOR_PRICE_ASC"
  | "FLOOR_PRICE_DESC"
  | "COUNT_ASC"
  | "COUNT_DESC";

const sortOptions: SortOption<SortTypes>[] = [
  { name: "Floor: Low to High", value: "FLOOR_PRICE_ASC" },
  { name: "Floor: High to Low", value: "FLOOR_PRICE_DESC" },
  { name: "Count: Low to High", value: "COUNT_ASC" },
  { name: "Count: High to Low", value: "COUNT_DESC" },
];

const TraitExplore = ({
  traitKey,
  collectionId,
  onRemoveTraitClicked,
  onFilterTraitClicked,
}: {
  collectionId: string;
  traitKey: string;
  onRemoveTraitClicked: () => void;
  onFilterTraitClicked: (traitType: string, value: any) => void;
}) => {
  const [traitData, setTraitData] = useState<any>(null);
  const [sort, setSort] = useState<SortOption>(sortOptions[0]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isNil(traitKey)) return;

    setLoading(true);
    fetchExploreTraits({ attributeKey: traitKey });
  }, [traitKey]);

  useEffect(() => {
    if (!traitData?.length || !sort) return;

    const sorted = sortTraitData(traitData, sort);
    setTraitData(sorted);
  }, [sort]);

  const fetchExploreTraits = async ({
    attributeKey,
  }: {
    attributeKey: string;
  }) => {
    try {
      let res = await exploreCollectionTraits({
        id: collectionId,
        key: attributeKey,
      });

      const sorted = sortTraitData(res, sort);
      setTraitData(sorted);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const sortTraitData = (data: any[], sort: SortOption) => {
    return sortBy(data, (d) => {
      if (sort.value === "FLOOR_PRICE_ASC") {
        return d.floorAskPrice || 9999999; // no floor price should be at the end
      } else if (sort.value === "FLOOR_PRICE_DESC") {
        return d.floorAskPrice ? d.floorAskPrice * -1 : -9999999; // no floor price should be at the begining
      } else if (sort.value === "COUNT_ASC") {
        return d.count ? d.count : 0;
      } else if (sort.value === "COUNT_DESC") {
        return d.count ? d.count * -1 : 0;
      }
    });
  };

  return (
    <>
      <div className="pt-8 px-10 flex justify-between items-center">
        <div className="bg-black inline-block text-sm text-white dark:bg-white dark:text-neutral-900 rounded-full pl-3 pr-2 py-2">
          <div className="flex items-center gap-3">
            <p>Exploring: {traitKey}</p>
            <button onClick={() => onRemoveTraitClicked?.()}>
              <Close className="text-white bg-neutral-800 dark:hover:bg-neutral-300 dark:bg-neutral-200 dark:text-neutral-500 rounded-full hover:bg-neutral-600 transition" />
            </button>
          </div>
        </div>
        <div>
          <Dropdown
            width={"64"}
            selectedOption={sort}
            setSelectedOption={setSort}
            options={sortOptions}
          />
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="py-8 px-10 justify-between grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-12">
          {traitData?.map((trait: any) => {
            return (
              <TraitExploreItem
                key={trait.value}
                traitType={trait.traitType}
                value={trait.value}
                floorAskPrice={trait.floorAskPrice}
                sampleAssets={trait.sampleAssets || []}
                assetCount={trait.count}
                addTraitFilter={() =>
                  onFilterTraitClicked(trait.traitType, trait.value)
                }
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default TraitExplore;
