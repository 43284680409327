import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export type ModalProps = React.PropsWithChildren<{
  title?: string;
  open?: boolean;
  onClose?: () => void;
  mobileFullscreen?: boolean;
}>;

export const Modal = ({
  children,
  title,
  open,
  onClose,
  mobileFullscreen,
}: ModalProps) => {
  const handleCloseClicked = () => {
    onClose && onClose();
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative" onClose={handleCloseClicked}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-40 bg-black bg-opacity-25 dark:bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div
            className={`flex items-center justify-center min-h-full text-center ${mobileFullscreen ? "md:p-4" : "p-4"}`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full md:max-w-xl text-left align-middle transition-all transform shadow-xl dark:bg-neutral-900 dark:text-white bg-neutral-100 ${mobileFullscreen ? "h-screen md:h-full max-w-full mt-6 rounded-t-3xl md:rounded-3xl" : "rounded-3xl"}`}
              >
                <div className="p-6 py-4">
                  <div className="flex items-center justify-between flex-shrink-0">
                    {title ? (
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        {title}
                      </Dialog.Title>
                    ) : null}
                  </div>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
