import { formatUnits } from "viem";
import { ActorRefFrom } from "xstate";

import { useAuth } from "@/client/components/common/AuthProvider";
import { AnimatedSpinner } from "@/client/components/common/AnimatedSpinner";
import { FullScreenConfetti } from "@/client/components/common/FullScreenConfetti";
import { LoginButton } from "@/client/components/common/LoginButton";
import Modal, { ModalProps } from "@/client/components/common/Modal";
import Button from "@/client/components/frame-design-system/buttons/Button";
import { Close } from "@/client/components/icons/close";
import { ErrorIcon } from "@/client/components/icons/error-icon";
import { cn } from "@/client/lib/classnames";
import { getAssetPath } from "@/client/lib/links";
import { purchaseInitiationMachine } from "@artblocks/sdk/dist/machines/purchase-initiation-machine";

import {
  ProjectSaleManagerMachineContext,
  usePurchaseInitiationMachine,
  usePurchaseTrackingMachine,
} from "../../ProjectSaleManagerMachineContext";

import { ERC20AllowanceApprovalForm } from "./ERC20ApprovalForm";
import { ProjectSaleTokenDisplay } from "./ProjectSaleTokenDisplay";
import { PurchaseForm } from "./PurchaseForm";
import { useFundWallet, usePrivy } from "@privy-io/react-auth";

export function PurchaseModal({
  open,
  onClose,
}: Omit<ModalProps, "title" | "children">) {
  const { isAuthenticated, isAuthenticating } = useAuth();

  const {
    ref: purchaseInitiationMachineRef,
    state: purchaseInitiationMachineState,
  } = usePurchaseInitiationMachine();

  const {
    ref: purchaseTrackingMachineRef,
    reset: resetPurchaseTrackingMachine,
    state: purchaseTrackingMachineState,
  } = usePurchaseTrackingMachine();

  const purchaseInProgress =
    purchaseInitiationMachineState?.matches("initiatingPurchase") ||
    purchaseTrackingMachineState?.matches("awaitingPurchaseConfirmation") ||
    purchaseTrackingMachineState?.matches("awaitingTokenSync");

  const projectSaleState = ProjectSaleManagerMachineContext.useSelector(
    (state) => {
      return state;
    },
    (a, b) => {
      return a.value === b.value;
    }
  );

  const handleClose = () => {
    if (purchaseInProgress) {
      return;
    }

    purchaseInitiationMachineRef?.send({ type: "RESET" });
    resetPurchaseTrackingMachine?.();
    onClose?.();
  };

  return (
    <Modal
      open={Boolean(open || purchaseTrackingMachineRef)}
      onClose={handleClose}
      mobileFullscreen={true}
    >
      {/* Note: This is required to get 32px padding all around beacuse of how Modal is styled */}
      <div className="px-2 py-4">
        {(() => {
          if (
            projectSaleState.matches("idle") &&
            !isAuthenticated &&
            !isAuthenticating
          ) {
            return (
              <div>
                <div className="flex justify-end font-medium">
                  <button
                    className="opacity-60 hover:opacity-100 "
                    onClick={handleClose}
                  >
                    <Close size={32} />
                  </button>
                </div>
                <div className="mt-1 mb-6">
                  <h3 className="font-medium tracking-tight text-center text-header-s">
                    Connect Wallet
                  </h3>
                  <p className="text-center text-p-lg opacity-60">
                    You must connect your wallet to purchase
                  </p>
                </div>
                <LoginButton size="large" className="w-full" />
              </div>
            );
          }

          if (projectSaleState.matches("idle") && isAuthenticating) {
            return (
              <div className="flex flex-col items-center justify-center">
                <AnimatedSpinner size={44} />
                <h3 className="font-medium tracking-tight text-center text-header-s">
                  Awaiting Signature
                </h3>
                <p className="text-center text-p-lg opacity-60">
                  Sign the transaction with your wallet to connect
                </p>
              </div>
            );
          }

          if (
            purchaseTrackingMachineRef ||
            (projectSaleState.matches("readyForPurchase") &&
              purchaseInitiationMachineRef)
          ) {
            return (
              <div>
                <div className="flex items-center justify-between mb-4 font-medium">
                  <h3 className="leading-tight tracking-tight text-xl md:text-header-s">
                    Checkout
                  </h3>
                  <button
                    disabled={purchaseInProgress}
                    className="transition-opacity duration-100 opacity-60 hover:opacity-100 disabled:opacity-0"
                    onClick={handleClose}
                  >
                    <Close size={32} />
                  </button>
                </div>
                <PurchaseFlow />
              </div>
            );
          }
        })()}
      </div>
    </Modal>
  );
}

function PurchaseFlow() {
  const { state: purchaseInitiationState, ref: purchaseInitiationMachineRef } =
    usePurchaseInitiationMachine();

  const {
    state: purchaseTrackingState,
    ref: purchaseTrackingMachineRef,
    reset: resetPurchaseTrackingMachine,
  } = usePurchaseTrackingMachine();

  const { user } = usePrivy();

  const purchaseTxHash = purchaseTrackingState?.context.purchaseTransactionHash;
  const { fundWallet } = useFundWallet();
  const addFunds = async () => {
    if (user && user.wallet) {
      await fundWallet(user.wallet.address);
    } else {
      console.error("No wallet address available");
    }
  };

  return (
    <div>
      <FullScreenConfetti
        show={Boolean(purchaseTrackingState?.matches("tokenReady"))}
      />
      <div>
        <ProjectSaleTokenDisplay txHash={purchaseTxHash} />
      </div>
      {(() => {
        if (purchaseInitiationState?.matches("error")) {
          const errorMessage =
            purchaseInitiationState.context.errorMessage ??
            "Something went wrong, please try again";
          const isInsufficientFundsError = errorMessage.includes("total cost");

          return (
            <PurchaseStepWrapper className="mt-6">
              <ErrorIcon size={44} />
              <PurchaseStepMessage
                message="Purchase Error"
                subMessage={errorMessage}
                className="mb-6"
              />
              {isInsufficientFundsError ? (
                <div className="flex flex-col gap-4 w-full">
                  <Button
                    size="large"
                    variant="primary"
                    onClick={() => {
                      addFunds();
                    }}
                  >
                    Add Funds
                  </Button>
                  <Button
                    size="large"
                    variant="secondary"
                    onClick={() => {
                      purchaseInitiationMachineRef?.send({ type: "RESET" });
                      resetPurchaseTrackingMachine?.();
                    }}
                  >
                    Try Again
                  </Button>
                </div>
              ) : (
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    purchaseInitiationMachineRef?.send({ type: "RESET" });
                    resetPurchaseTrackingMachine?.();
                  }}
                >
                  Try Again
                </Button>
              )}
            </PurchaseStepWrapper>
          );
        }

        if (purchaseInitiationState?.matches("userIneligibleForPurchase")) {
          return (
            <PurchaseStepWrapper className="mt-4">
              <ErrorIcon size={44} className="text-current opacity-80" />
              <PurchaseStepMessage
                message="Sorry, you are not eligible to purchase this project at this time."
                subMessage={
                  purchaseInitiationState.context.userIneligibilityReason ??
                  "Reason unknown"
                }
              />
            </PurchaseStepWrapper>
          );
        }

        if (
          purchaseTrackingState &&
          purchaseTrackingState.matches("tokenReady")
        ) {
          return (
            <>
              <PurchaseStepWrapper className="mt-8">
                <p className="text-xl md:text-2xl">Purchase Successful! 🎉</p>
                <p className="text-p-m opacity-60 hidden md:block">
                  Woohoo! Check out your new piece.
                </p>
              </PurchaseStepWrapper>
              <div className="flex flex-col gap-4 mt-8 sm:flex-row">
                <div className="flex-1">
                  <Button
                    size="large"
                    className="w-full"
                    variant="secondary"
                    href={`${getAssetPath(
                      purchaseTrackingState.context.mintedToken
                        ?.contract_address ?? "",
                      purchaseTrackingState.context.mintedToken?.token_id ?? ""
                    )}?generator=true`}
                    as="Link"
                  >
                    View piece
                  </Button>
                </div>
                <div className="flex-1">
                  <Button
                    size="large"
                    className="w-full"
                    variant="primary"
                    onClick={() => {
                      purchaseInitiationMachineRef?.send({ type: "RESET" });
                      resetPurchaseTrackingMachine?.();
                    }}
                  >
                    Purchase another
                  </Button>
                </div>
              </div>
            </>
          );
        }

        if (
          purchaseTrackingState &&
          purchaseTrackingState.matches("awaitingTokenSync")
        ) {
          return (
            <PurchaseStepWrapper className="mt-4">
              <AnimatedSpinner size={44} className="mb-1" />
              <PurchaseStepMessage
                message="Awaiting indexing..."
                subMessage="We're waiting for your token to be indexed. This may take a few moments."
              />
            </PurchaseStepWrapper>
          );
        }

        if (
          purchaseTrackingState &&
          purchaseTrackingState.matches("awaitingPurchaseConfirmation")
        ) {
          return (
            <PurchaseStepWrapper className="mt-4">
              <AnimatedSpinner size={44} className="mb-1" />
              <PurchaseStepMessage
                message="Awaiting confirmation..."
                subMessage="We're waiting for your transaction to go through. This should only take a moment"
              />
            </PurchaseStepWrapper>
          );
        }

        if (
          purchaseInitiationState?.matches(
            "awaitingERC20AllowanceApprovalAmount"
          )
        ) {
          return (
            <ERC20AllowanceApprovalForm
              purchaseInitiationMachineRef={
                purchaseInitiationMachineRef as ActorRefFrom<
                  typeof purchaseInitiationMachine
                >
              }
            />
          );
        }

        if (
          purchaseInitiationState?.matches(
            "awaitingERC20AllowanceApprovalInitiation"
          )
        ) {
          return (
            <PurchaseStepWrapper className="mt-4">
              <AnimatedSpinner size={44} className="mb-1" />
              <PurchaseStepMessage
                message="Awaiting signature..."
                subMessage="Please sign the purchase transaction using your wallet"
              />
            </PurchaseStepWrapper>
          );
        }

        if (
          purchaseInitiationState?.matches(
            "waitingForERC20AllowanceApprovalConfirmation"
          )
        ) {
          return (
            <PurchaseStepWrapper className="mt-4">
              <AnimatedSpinner size={44} className="mb-1" />
              <PurchaseStepMessage
                message="Awaiting confirmation..."
                subMessage="We're waiting for your transaction to go through. This should only take a moment"
              />
            </PurchaseStepWrapper>
          );
        }

        if (
          purchaseInitiationMachineRef &&
          purchaseInitiationState?.matches("erc20AllowanceApproved")
        ) {
          const currencySymbol =
            purchaseInitiationState.context.project?.minter_configuration
              ?.currency_symbol ?? "ERC-20";
          const approvalAmount = formatUnits(
            purchaseInitiationState.context.erc20ApprovalAmount ?? BigInt(0),
            purchaseInitiationState.context.additionalPurchaseData?.decimals ??
              18
          );

          return (
            <div className="mt-4">
              <PurchaseStepMessage
                message="Spending allowance approved"
                subMessage={`You have approved the spending of ${approvalAmount} ${currencySymbol}`}
              />
              <div className="flex gap-4 mt-4">
                <Button
                  variant="secondary"
                  size="large"
                  className="flex-1"
                  onClick={() => {
                    purchaseInitiationMachineRef.send({ type: "RESET" });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className="flex-1"
                  onClick={() => [
                    purchaseInitiationMachineRef.send({
                      type: "INITIATE_PURCHASE",
                    }),
                  ]}
                >
                  Purchase
                </Button>
              </div>
            </div>
          );
        }

        if (
          purchaseInitiationState?.matches("purchaseInitiated") ||
          purchaseInitiationState?.matches("initiatingPurchase")
        ) {
          return (
            <PurchaseStepWrapper className="mt-4">
              <AnimatedSpinner size={44} className="mb-1" />
              <PurchaseStepMessage
                message="Awaiting signature..."
                subMessage="Please sign the purchase transaction using your wallet"
              />
            </PurchaseStepWrapper>
          );
        }

        if (
          purchaseInitiationState?.matches(
            "gettingUserPurchaseEligibilityAndContext"
          ) ||
          purchaseInitiationState?.matches("readyForPurchase")
        ) {
          return (
            <PurchaseForm
              purchaseInitiationMachineRef={
                purchaseInitiationMachineRef as ActorRefFrom<
                  typeof purchaseInitiationMachine
                >
              }
            />
          );
        }

        return null;
      })()}
    </div>
  );
}

export function PurchaseStepWrapper({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div className={cn(`flex flex-col items-center`, className)}>
      {children}
    </div>
  );
}

export function PurchaseStepMessage({
  message,
  subMessage,
  className,
}: {
  message: string;
  subMessage: string;
  className?: string;
}) {
  return (
    <div className={cn(`text-center flex-1`, className)}>
      <p className="font-medium text-p-xl">{message}</p>
      <p className="opacity-60 text-p-m">{subMessage}</p>
    </div>
  );
}
